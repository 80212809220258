import {
  format,
  getTime,
  formatDistanceToNow,
  startOfWeek as dStartOfWeek,
  startOfMonth as dStartOfMonth,
  startOfYear as dStartOfYear,
  endOfWeek as dEndOfWeek,
  endOfMonth as dEndOfMonth,
  endOfYear as dEndOfYear,
  intervalToDuration,
  Duration,
  differenceInHours,
} from "date-fns";

export const currentDate = (fm: any = "dd MMM yyyy") => {
  return format(new Date(), fm);
};

export const fDate = (date: any, fm: any = "dd MMM yyyy") => {
  return date ? format(new Date(date), fm) : "";
};

export const fDateTime = (date: any, fm: any = "dd MMM yyyy p") => {
  return date ? format(new Date(date), fm) : "";
};

export const fTimestamp = (date: any) => {
  return date ? getTime(new Date(date)) : "";
};

export const fToNow = (date: any) => {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
};

export const fDateStart = (date: Date) => {
  if (date) {
    return format(date, "yyyy-MM-dd") + " 00:00:00";
  } else {
    return "";
  }
};

export const fDateEnd = (date: Date) => {
  if (date) {
    return format(date, "yyyy-MM-dd") + " 23:59:59";
  } else {
    return "";
  }
};

export function secondsToDuration(seconds: number): Duration {
  return milliSecondsToDuration(seconds * 1000);
}
export function milliSecondsToDuration(milliSeconds: number): Duration {
  const epoch = new Date(0);
  const secondsAfterEpoch = new Date(milliSeconds);
  return intervalToDuration({
    start: epoch,
    end: secondsAfterEpoch,
  });
}

export const secondsToHours = (input: number) => {
  let decimalTime = input;
  let hours: number | string = Math.floor(decimalTime / (60 * 60));
  decimalTime = decimalTime - hours * 60 * 60;
  let minutes: number | string = Math.floor(decimalTime / 60);
  decimalTime = decimalTime - minutes * 60;
  let seconds: number | string = Math.round(decimalTime);
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const startOfWeek = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dStartOfWeek(new Date(date)), fm) : "";
};
export const startOfMonth = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dStartOfMonth(new Date(date)), fm) : "";
};
export const startOfYear = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dStartOfYear(new Date(date)), fm) : "";
};
export const endOfWeek = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dEndOfWeek(new Date(date)), fm) : "";
};
export const endOfMonth = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dEndOfMonth(new Date(date)), fm) : "";
};
export const endOfYear = (date: any, fm: any = "yyyy-MM-dd") => {
  return date ? format(dEndOfYear(new Date(date)), fm) : "";
};

export const addZeroBefore = (n: number | undefined | null) => {
  return n ? (n < 10 ? "0" : "") + n : "00";
};

export const diffInHours = (d1: Date, d2: Date) => {
  return differenceInHours(d1, d2);
};

export const isFutureDate = (date: Date) => {
  return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
};
