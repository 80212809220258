import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Theme,
  CSSObject,
  useTheme,
} from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
import getNavConfig from "./config";
import { RootState } from "../../../pages/store";
import { AppUser } from "../../../pages/api";
import Iconify from "../../../components/iconify/Iconify";

const NAV_WIDTH = 281;
const CLOSE_NAV_WIDTH = 70;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAV_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// ----------------------------------------------------------------------

Nav.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function Nav({ open, setOpen }: any) {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const appUser: AppUser = useSelector((state: RootState) => state.appUser);

  // const [open, setOpen] = useState(false);

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (open && !isDesktop) {
      setOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 1, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      {isDesktop && (
        <Iconify
          icon={open ? "eva:chevron-left-outline" : "eva:chevron-right-outline"}
          sx={{
            color: "text.secondary",
            bgColor: "action.disabledOpacity",
            borderColor: "action.disabledBackground",
            backdropFilter: "blur(6px)",
            cursor: "pointer",
            borderRadius: "50%",
            borderWidth: "1px",
            borderStyle: "dashed",
            width: 25,
            height: 25,
            position: "fixed",
            left: open ? NAV_WIDTH - 13 : CLOSE_NAV_WIDTH - 18,
            top: 25,
            zIndex: 100,
          }}
          onClick={() => setOpen(!open)}
        />
      )}

      {appUser && appUser.user && (!isDesktop || open) && (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none">
            <StyledAccount>
              <Avatar src={account.photoURL} alt="photoURL" />

              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {appUser.user.name}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textTransform: "lowercase" }}
                >
                  {appUser.user.role}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>
      )}

      <NavSection
        data={getNavConfig(
          t,
          appUser && appUser.user && appUser.user.role ? appUser.user.role : ""
        )}
        isDesktop={isDesktop}
        open={open}
      />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          <Box
            component="a"
            href="https://ideasnewit.com/"
            target="_blank"
            title="IdeasNew InfoTech"
            sx={{
              width: open ? "65%" : "100%",
              position: "absolute",
              top: -50,
            }}
          >
            <Box
              component="img"
              src={open ? "/assets/logo-init.png" : "/assets/logo-init-2.png"}
              // sx={{ width: 100, position: "absolute", top: -50 }}
            />
          </Box>

          {/*
          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              From only $69
            </Typography>
          </Box> */}

          {/* <a
            href="https://ideasnewit.com/"
            target="_blank"
            title="IdeasNew InfoTech"
          >
            IdeasNew InfoTech
          </a> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: open ? NAV_WIDTH : CLOSE_NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: open ? NAV_WIDTH : CLOSE_NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",

              ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
              }),
              ...(!open && {
                ...closedMixin(theme),
                "& .MuiDrawer-paper": closedMixin(theme),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={open}
          onClose={setOpen}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
