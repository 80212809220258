import { Helmet } from "react-helmet-async";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stack, Button, Container, Typography } from "@mui/material";
import Iconify from "../../components/iconify";
import {
  useGetUserQuery,
  useEditUserMutation,
  useDestroyUserMutation,
} from "./slice";
import { Message } from "../../models/message";
import Modal from "../../components/modal";
import { Form } from "./Form";
import Alert from "../../components/alert";
import Loader from "../../components/loader";

export const Edit = () => {
  const params = useParams();
  const { id } = params;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<Message | null>(null);
  const [hasDelete, setHasDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const result = useGetUserQuery(id + "");
  const [update] = useEditUserMutation();
  const [destroy] = useDestroyUserMutation();

  const initialValues = useMemo(() => {
    if (result.isSuccess && result.data.user) {
      return { ...result.data.user, password: "" };
    } else {
      return {
        name: "",
        userName: "",
        password: "",
        role: "",
        phone: "",
        email: "",
        address: "",
        paymentRate: "",
        paymentTerm: "hour",
      };
    }
  }, [result.isSuccess, result.data?.user]);

  useEffect(() => {
    if (message?.type && message?.message) {
      window.scrollTo(0, 0);
    }
  }, [message?.type, message?.message]);

  const handleDestroy = useCallback(async () => {
    if (id && id.length && id !== "0") {
      setMessage(null);
      setIsDeleting(true);
      try {
        const { message, error, invalidData } = await destroy(id).unwrap();
        setIsDeleting(false);
        setHasDelete(false);
        if (message) {
          navigate("/user", {
            state: { message: { type: "success", message } },
          });
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: invalidData.id });
        }
      } catch (error: any) {
        setIsDeleting(false);
        setHasDelete(false);
        setMessage({ type: "error", message: error.message });
      }
    }
  }, [id, navigate, destroy]);

  const onSubmit = async (values: any, actions: any) => {
    try {
      if (values.paymentRate === "") {
        values.paymentRate = 0;
      }
      setMessage(null);
      actions.setErrors(null);
      const { user, error, invalidData } = await update(values).unwrap();
      actions.setSubmitting(false);
      if (user) {
        const message = {
          type: "success",
          message: t("Userupdatedsuccessfully"),
        };
        navigate("/user", {
          state: { message },
        });
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        actions.setErrors(invalidData);
        setMessage({ type: "error", message: t("Pleasecorrecterrors") });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("User")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("EditUser")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:arrow-back-fill" />}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("Back")}
          </Button>
        </Stack>

        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Form
          initialValues={initialValues}
          isLoading={result && result.isFetching}
          onSubmit={onSubmit}
          setHasDelete={setHasDelete}
        />
      </Container>

      {hasDelete && (
        <Modal
          title={t("DeleteUser")}
          message={t("suredeleteUser")}
          okLabel={t("Delete") + ""}
          onOk={handleDestroy}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => setHasDelete(false)}
          isLoading={isDeleting}
          okIcon="eva:trash-2-fill"
          okSX={{
            bgcolor: "error.dark",
            "&:hover": {
              bgcolor: "error.darker",
            },
          }}
        />
      )}

      {result && result.isFetching && <Loader />}
    </>
  );
};
