import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Iconify from "../iconify";
import { IconButton } from "@mui/material";

export interface ModalProps {
  title: string;
  onOk?: any;
  onCancel?: any;
  okLabel?: string;
  cancelLabel?: string;
  message?: string | null;
  children?: any;
  isLoading?: boolean;
  okIcon?: string;
  okSX?: any;
  fullScreen?: boolean;
}

export default ({
  title,
  onOk,
  onCancel,
  okLabel,
  cancelLabel,
  message,
  children,
  isLoading,
  okIcon,
  okSX = {},
  fullScreen = false,
}: ModalProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tempFullScreen = fullScreen
    ? true
    : useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={tempFullScreen}
      open={true}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        // sx={{ borderBottom: "1px solid #CCC" }}
      >
        <span>{title}</span>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onCancel}
          aria-label="close"
          sx={{ float: "right", fontWeight: "bold" }}
        >
          <Iconify icon={"iconoir:cancel"} sx={{ color: "error.dark" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children ? children : <DialogContentText>{message}</DialogContentText>}
      </DialogContent>
      {(onOk || cancelLabel) && (
        <DialogActions>
          <Button autoFocus disabled={isLoading} onClick={onCancel}>
            {cancelLabel ? cancelLabel : t("Cancel")}
          </Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={okIcon && okIcon.length > 0 && <Iconify icon={okIcon} />}
            variant="contained"
            sx={okSX}
            onClick={onOk}
          >
            {okLabel ? okLabel : t("Ok")}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
