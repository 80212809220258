import { useDroppable } from "@dnd-kit/core";
import { Grid } from "@mui/material";

export function Droppable(props: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: {
      status: props.status,
    },
  });
  const style = {
    // opacity: isOver ? 1 : 0.5,
  };

  return (
    <Grid
      ref={setNodeRef}
      style={style}
      sx={{
        // border: "1px solid #CCC",
        m: 0,
        minHeight: "500px",
        backgroundColor: "#FFF",
        ...props.sx,
      }}
    >
      {props.children}
    </Grid>
  );
}
