import { Stack, Alert } from "@mui/material";
import { Message } from "../../models/message";

export interface IAlert {
  message: Message;
  onClose: () => void;
}

export default ({ message, onClose }: IAlert) => {
  if (message && message?.message) {
    return (
      <Stack sx={{ width: "100%", mt: 2, mb: 2 }} spacing={2}>
        <Alert
          severity={message && message?.type ? message?.type : "info"}
          onClose={onClose}
        >
          {message?.message}
        </Alert>
      </Stack>
    );
  } else {
    return null;
  }
};
