// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: any) => (
  <SvgColor src={`/assets/icons/navbar/${name}`} sx={{ width: 1, height: 1 }} />
);

const getNavConfig = (t: any, role: string) => {
  let navConfig = [
    {
      title: t("Dashboard"),
      path: "/dashboard",
      icon: icon("dashboard.svg"),
    },
    {
      title: t("View All Appointments"),
      path: "/appointments",
      icon: icon("viewappointments.svg"),
    },
    {
      title: t("Add Appointment"),
      path: "/new-appointment",
      icon: icon("addappointment.svg"),
    },
    {
      title: t("Today's Appointment"),
      path: "/todays-appointments",
      icon: icon("card.svg"),
    },
    {
      title: t("Settings"),
      path: "/settings",
      icon: icon("settings.svg"),
    },
    // {
    //   title: t("User"),
    //   path: "/user",
    //   icon: icon("ic_user.svg"),
    // }
  ];
  return navConfig;
};

export default getNavConfig;
