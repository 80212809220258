import { Helmet } from "react-helmet-async";
import { useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Container, Typography, Stack, Grid, Box } from "@mui/material";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import {
  useGetAppointmentsQuery,
  useLazyGetAllAppointmentsQuery,
  useDestroyAppointmentMutation,
} from "../appointment/slice";
import { RootState } from "../store";
import { fDate, fDateTime } from "../../utils/formatTime";
import Label from "../../components/label/Label";
import Table from "../../components/table/Table";
import { getScanName, getTime } from "../../utils";
import Modal from "../../components/modal/Modal";
// import { useGetScansQuery } from "../user/slice";
import { S3_BUCKET_BASE_URL } from "../../constants";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify/Iconify";
import exp from "constants";

export default () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state: RootState) => state.appUser.language);
  const [message, setMessage] = useState<Message | null>(null);
  // const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const [query, setQuery] = useState(`?page=1&limit=10&sort=date desc`);
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  // const scanResult = useGetScansQuery(scanQuery);
  // const scanList: any = scanResult.isSuccess
  //   ? scanResult.data && scanResult.data.scans
  //     ? scanResult.data.scans
  //     : []
  //   : [];

  const result = useGetAppointmentsQuery(query);
  const [destroy] = useDestroyAppointmentMutation();
  const data: any = result.isSuccess ? (result.data ? result.data : {}) : null;

  const [getExportData, { error }] = useLazyGetAllAppointmentsQuery();

  const columns = useMemo(
    () => [
      {
        id: "date",
        label: t("Date"),
        callback: (row: any) => fDate(row.date),
      },
      {
        id: "time",
        label: t("Time"),
        callback: (row: any) => getTime(row.time),
      },
      {
        id: "scanId",
        label: t("Scan"),
        callback: (row: any) => {
          return getScanName(row.scans);
        },
      },
      {
        id: "patientName",
        label: t("Patient Name"),
        // avatarUrlId: "avatarUrl",
      },
      { id: "patientAge", label: t("Patient Age") },
      { id: "patientGender", label: t("Patient Gender") },
      { id: "referringDoctorName", label: t("Refered By") },
      { id: "token", label: t("Token") },
      {
        id: "status",
        label: t("Status"),
        callback: (row: any) => (
          <Label
            color={
              row.status === 0
                ? "default"
                : row.status === 1
                ? "warning"
                : row.status === 2
                ? "info"
                : row.status === 3
                ? "secondary"
                : row.status === 4
                ? "error"
                : "success"
            }
          >
            {row.status === 0
              ? "Draft"
              : row.status === 1
              ? "Confirmed"
              : row.status === 2
              ? "Scan In Progress"
              : row.status === 3
              ? "Report In Progress"
              : row.status === 4
              ? "Report Ready"
              : "Completed"}
          </Label>
        ),
      },
      {
        id: "report",
        label: t("Report"),
        callback: (row: any) => {
          if (row.reports && row.reports.length > 0) {
            return (
              <Grid>
                {row.reports.map((f: any, i: number) => (
                  <Grid key={"rpt-" + i}>
                    <a href={S3_BUCKET_BASE_URL + f.fileName} target="_blank">
                      {f.name}
                    </a>
                  </Grid>
                ))}
              </Grid>
            );
          } else {
            return "";
          }
        },
      },
      {
        id: "createdAt",
        label: t("CreatedAt"),
        callback: (row: any) => fDateTime(row.createdAt),
      },
    ],
    [language]
  );

  const actions = useMemo(
    () => [
      {
        label: t("Edit"),
        color: "info.dark",
        icon: "eva:edit-fill",
        onClick: (row: any) => {
          navigate(`/appointment/edit/${row.id}`);
        },
      },
      {
        label: t("Delete"),
        color: "error.main",
        icon: "eva:trash-2-outline",
        onClick: (row: any) => {
          setDeleteId(row.id);
        },
      },
    ],
    [language]
  );

  const handleQuery = useCallback((query: string) => {
    if (query.length) {
      setQuery(query);
    }
  }, []);

  const onDelete = useCallback(async () => {
    if (deleteId.length) {
      setIsDeleting(true);
      try {
        const { message, error, invalidData } = await destroy(
          deleteId
        ).unwrap();
        setIsDeleting(false);
        setDeleteId("");
        if (message) {
          setMessage({ type: "success", message });
          setQuery(query + " ");
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: invalidData.id });
        }
      } catch (error: any) {
        setIsDeleting(false);
        setDeleteId("");
        setMessage({ type: "error", message: error.message });
      }
    }
  }, [deleteId, destroy]);

  const onExport = async () => {
    try {
      setIsExporting(true);
      let exportQuery = "";
      let queryCopy = query;
      queryCopy = queryCopy.replace("?", "");
      const arrQuery = queryCopy.split("&");
      if (arrQuery && arrQuery.length > 0) {
        arrQuery.map((q: string) => {
          if (q.indexOf("page=") >= 0) {
            exportQuery += (exportQuery.length > 0 ? "&" : "") + "page=1";
          } else if (q.indexOf("limit=") >= 0) {
            exportQuery += (exportQuery.length > 0 ? "&" : "") + "limit=all";
          } else {
            exportQuery += (exportQuery.length > 0 ? "&" : "") + q;
          }
        });
      }
      const { appointments, error, invalidData } = await getExportData(
        "?" + exportQuery
      ).unwrap();
      setIsExporting(false);
      if (appointments) {
        const worksheet = XLSX.utils.json_to_sheet(appointments);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, new Date().getTime() + ".xlsx");
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
    } catch (error: any) {
      setIsExporting(false);
      setMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Dashboard")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container maxWidth="xl">
        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">{t("Appointments")}</Typography>
        </Stack>
        <Grid sx={{ textAlign: "right", mb: 1 }}>
          <LoadingButton
            type="button"
            loading={isExporting}
            loadingPosition="start"
            startIcon={<Iconify icon="mingcute:download-fill" />}
            variant="contained"
            size="large"
            onClick={onExport}
          >
            {t("Export")}
          </LoadingButton>
        </Grid>
        <Grid>
          <Table
            columns={columns}
            data={data && data.appointments ? data.appointments : []}
            actions={actions}
            pagination={data && data.pagination ? data.pagination : null}
            searchFormFields={[
              {
                name: "patientName",
                type: "text",
                label: t("Patient Name"),
              },
              {
                name: "fromDate",
                type: "date",
                label: t("From Date"),
              },
              {
                name: "toDate",
                type: "date",
                label: t("To Date"),
              },
              {
                name: "token",
                type: "number",
                label: t("Token"),
              },
              {
                name: "referringDoctorName",
                type: "text",
                label: t("Refered By"),
              },
              // {
              //   name: "scanId",
              //   type: "autocomplete",
              //   label: t("Scan"),
              //   options: scanList ? scanList : [],
              //   renderOption: (props: any, option: any) => (
              //     <Box
              //       component="li"
              //       sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
              //       {...props}
              //     >
              //       <Grid sx={{ width: "100%" }}>
              //         <Grid sx={{ display: "bolck", fontWeight: "bold" }}>
              //           {option.name}
              //         </Grid>
              //       </Grid>
              //     </Box>
              //   ),
              // },
            ]}
            searchFormInitialValues={{
              patientName: "",
              fromDate: "",
              toDate: "",
              token: "",
              referringDoctorName: "",
              // scanId: "",
            }}
            handleQuery={handleQuery}
            defaultOrderBy="date"
            defaultOrder="desc"
            isLoading={result.isFetching}
          />
        </Grid>
        {deleteId && deleteId.length > 0 && (
          <Modal
            title={t("Delete Appointment")}
            message={t("Are you sure you want to delete this appointment?")}
            okLabel={t("Delete") + ""}
            onOk={onDelete}
            cancelLabel={t("Cancel") + ""}
            onCancel={() => setDeleteId("")}
            isLoading={isDeleting}
            okIcon="eva:trash-2-fill"
            okSX={{
              bgcolor: "error.dark",
              "&:hover": {
                bgcolor: "error.darker",
              },
            }}
          />
        )}
      </Container>
    </>
  );
};
