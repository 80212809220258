import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
// components
import Iconify from "../../components/iconify";
import { DraftLogin } from "../../pages/api";
import { useLoginMutation } from "../../pages/login/loginSlice";
import { Schema } from "./Schema";
import { AUTH_APP_USER } from "../../constants";
import { Message } from "../../models/message";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [loginPost] = useLoginMutation();
  const initialValues: DraftLogin = { userName: "", password: "" };
  const [message, setMessage] = useState<Message | null>(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema(t)}
      onSubmit={async (values, actions) => {
        try {
          setMessage(null);
          const { appUser, error, invalidData }: any = await loginPost(
            values
          ).unwrap();
          actions.setSubmitting(false);
          if (appUser) {
            localStorage.setItem(AUTH_APP_USER, JSON.stringify(appUser));
            const message = { type: "success", message: t("Loginsuccess") };
            // history.push({
            //     pathname: "/",
            //     state: { message }
            // });
            window.location.href = "/dashboard";
          }
          if (error) {
            setMessage({ type: "error", message: error });
          }
          if (invalidData) {
            actions.setErrors(invalidData);
            setMessage({ type: "error", message: t("Pleasecorrecterrors2") });
          }
        } catch (error: any) {
          setMessage({ type: "error", message: error.message });
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Stack spacing={3} sx={{ mb: 4 }}>
            <Field
              name="userName"
              render={({ field }: any) => (
                <TextField
                  name="userName"
                  label={t("UserName")}
                  type="text"
                  placeholder={"" + t("EnterUserName")}
                  required={true}
                  autoFocus
                  {...field}
                />
              )}
            />

            <Field
              name="password"
              render={({ field }: any) => (
                <TextField
                  name="password"
                  label={t("Password")}
                  type={showPassword ? "text" : "password"}
                  placeholder={"" + t("EnterPassword")}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
          </Stack>

          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <span>
              <Checkbox name="remember" />
              <span>{t("Rememberme")}</span>
            </span>
            <Link variant="subtitle2" underline="hover">
              {t("Forgotpassword")}
            </Link>
          </Stack> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={props.isSubmitting}
            // disabled={props.isSubmitting}
            // onClick={handleClick}
          >
            {t("Login")}
          </LoadingButton>
          {message && message.message && (
            <div style={{ marginTop: "15px", color: theme.palette.error.main }}>
              {message.message}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
}
