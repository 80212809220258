import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
// components
import Logo from "../../components/logo";

// ----------------------------------------------------------------------

const StyledContainer = styled("div")(({ theme }) => ({
  // width: "100vw",
  // height: "100vh",
  // position: "fixed",

  minHeight: "100%",
  backgroundImage: "url(/assets/illustrations/background-frame.jpg)",
  // backgroundRepeat: "repeat-x",
  backgroundPosition: "left",
  backgroundColor: "rgb(255, 255, 255)",
  backgroundSize: "cover",
}));

const StyledHeader = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <StyledContainer>
      <StyledHeader>
        <Logo />
      </StyledHeader>

      <Outlet />
    </StyledContainer>
  );
}
