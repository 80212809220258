import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { makeStyles } from "@mui/styles";
import {
  useVerifyOTPMutation,
  useResendOTPMutation,
} from "../appointment/slice";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify/Iconify";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    width: "100%",
    margin: "3rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

export default () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOTP] = useState("");

  const [verify] = useVerifyOTPMutation();
  const [resend] = useResendOTPMutation();

  useEffect(() => {
    const mn = localStorage.getItem("mobileNumber");
    const isotpverified = localStorage.getItem("isotpverified");
    if (mn && mn.length > 0 && isotpverified && isotpverified === "yes") {
      navigate("/appointment");
    } else {
      if (mn && mn.length === 10) {
        setMobileNumber(mn);
      } else {
        navigate("/mobile");
      }
    }
  }, []);

  const handleChange = (newValue: string) => {
    setOTP(newValue);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (mobileNumber && mobileNumber.length === 10 && otp && otp.length === 4) {
      try {
        setMessage(null);
        setIsLoading(true);
        const { patient, error, invalidData } = await verify({
          phone: mobileNumber,
          otp,
        }).unwrap();
        setIsLoading(false);
        if (patient && patient.phone) {
          setMessage({
            type: "success",
            message: t("OTP verification success.") + " / ओटीपी सत्यापन सफल.",
          });
          localStorage.setItem("isotpverified", "yes");
          navigate("/appointment");
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({
            type: "error",
            message:
              t("Failed to verify OTP. Please try again.") +
              " / ओटीपी सत्यापित करने में विफल. कृपया पुन: प्रयास करें।.",
          });
        }
      } catch (error: any) {
        setMessage({ type: "error", message: error.message });
      }
    } else {
      setMessage({
        type: "error",
        message: t("Please fill the valid OTP.") + " / कृपया वैध ओटीपी भरें।.",
      });
    }
  };

  const handleResend = async () => {
    try {
      setMessage(null);
      setIsLoading(true);
      const { isSent, error, invalidData } = await resend({
        phone: mobileNumber,
      }).unwrap();
      setIsLoading(false);
      if (isSent) {
        setMessage({
          type: "success",
          message:
            t("OTP sent to " + mobileNumber) +
            ". / ओटीपी " +
            mobileNumber +
            " पर भेजा गया.",
        });
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        setMessage({
          type: "error",
          message:
            t("Failed to resend OTP. Please try again.") +
            " / ओटीपी पुनः भेजने में विफल. कृपया पुन: प्रयास करें।.",
        });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("OTP")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <Card className={classes.root}>
            <CardHeader
              title={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="h2">
                    {t("EnterOTP")} / ओटीपी दर्ज करें
                  </Typography>

                  <IconButton
                    title="Go back to enter mobile number"
                    onClick={() => {
                      navigate("/mobile");
                    }}
                  >
                    <Iconify
                      icon="eva:arrow-back-fill"
                      sx={{ color: "primary.dark" }}
                      width={30}
                    />
                  </IconButton>
                </Stack>
              }
            />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <MuiOtpInput
                  length={4}
                  value={otp}
                  onChange={handleChange}
                  autoFocus={true}
                />
                <Typography
                  sx={{
                    color: "#637381",
                    lineHeight: "1.5",
                    fontSize: "0.85rem",
                    fontWeight: "400",
                    marginTop: "3px",
                    marginLeft: "0px",
                    textAlign: "left",
                  }}
                >
                  {t("OTPHelperText") +
                    mobileNumber +
                    " / कृपया " +
                    mobileNumber +
                    " पर भेजा गया ओटीपी दर्ज करें"}
                </Typography>
                {isLoading ? (
                  <Typography
                    sx={{
                      lineHeight: "1.5",
                      // fontSize: "0.85rem",
                      fontWeight: "500",
                      marginTop: "3px",
                      textAlign: "right",
                    }}
                  >
                    &nbsp;
                  </Typography>
                ) : (
                  <Typography
                    title="Resend OTP / ओटीपी पुनः भेजें"
                    sx={{
                      color: "#2065D1",
                      lineHeight: "1.5",
                      // fontSize: "0.85rem",
                      fontWeight: "500",
                      marginTop: "3px",
                      textAlign: "right",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleResend}
                  >
                    {t("Resend") + " / पुन: भेजें"}
                  </Typography>
                )}

                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  loadingPosition="start"
                  // startIcon={<Iconify icon="eva:save-fill" />}
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{ mt: 1 }}
                >
                  {t("Verify") + " / सत्यापित करें"}
                </LoadingButton>
              </form>
            </CardContent>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
};
