import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Typography, Card, CardContent } from "@mui/material";
import { RootState } from "../store";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { useGetAppointmentsQuery } from "../appointment/slice";
import { fDate, fDateEnd, fDateStart } from "../../utils/formatTime";
import { Appointment } from "../api";
import Loader from "../../components/loader/Loader";
import { getScanName } from "../../utils/index";

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appUser = useSelector((state: RootState) => state.appUser);
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(
    `?fromDate=${fDateStart(new Date())}&toDate=${fDateEnd(
      new Date()
    )}&page=1&limit=all&sort=token asc`
  );

  const result = useGetAppointmentsQuery(query);
  const data: any = result.isSuccess
    ? result.data && result.data.appointments
      ? result.data.appointments
      : null
    : null;

  return (
    <>
      <Helmet>
        <title>{`${t("Dashboard")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container maxWidth="xl" style={{ maxWidth: "100%", padding: 0 }}>
        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Grid container style={{ overflow: "hidden" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "warning.dark" }}
            >
              {t("Appointment Confirmed")}
            </Typography>

            <Grid
              sx={{
                m: 0,
                minHeight: "500px",
                backgroundColor: "#FFF",
                borderRight: "1px solid #CCC",
              }}
            >
              {data &&
                data.length > 0 &&
                data
                  .filter((a: Appointment) => a.status === 1)
                  .map((a: Appointment, i: number) => {
                    return (
                      <Card sx={{ m: 1, color: "warning.dark" }}>
                        <CardContent>
                          <Typography>{`Token: ${a.token}`}</Typography>
                          <Typography>{a.patientName}</Typography>
                          <Typography>{a.phone}</Typography>
                          <Typography>
                            {a.scans ? getScanName(a.scans) : ""}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "info.dark" }}
            >
              {t("Scan In Progress")}
            </Typography>
            <Grid
              sx={{
                m: 0,
                minHeight: "500px",
                backgroundColor: "#FFF",
                borderRight: "1px solid #CCC",
              }}
            >
              {data &&
                data.length > 0 &&
                data
                  .filter((a: Appointment) => a.status === 2)
                  .map((a: Appointment, i: number) => {
                    return (
                      <Card sx={{ m: 1, color: "info.dark" }}>
                        <CardContent>
                          <Typography>{`Token: ${a.token}`}</Typography>
                          <Typography>{a.patientName}</Typography>
                          <Typography>{a.phone}</Typography>
                          <Typography>
                            {a.scans ? getScanName(a.scans) : ""}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "secondary.dark" }}
            >
              {t("Report In Progress")}
            </Typography>
            <Grid
              sx={{
                m: 0,
                minHeight: "500px",
                backgroundColor: "#FFF",
                borderRight: "1px solid #CCC",
              }}
            >
              {data &&
                data.length > 0 &&
                data
                  .filter((a: Appointment) => a.status === 3)
                  .map((a: Appointment, i: number) => {
                    return (
                      <Card sx={{ m: 1, color: "secondary.dark" }}>
                        <CardContent>
                          <Typography>{`Token: ${a.token}`}</Typography>
                          <Typography>{a.patientName}</Typography>
                          <Typography>{a.phone}</Typography>
                          <Typography>
                            {a.scans ? getScanName(a.scans) : ""}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "error.dark" }}
            >
              {t("Report Ready")}
            </Typography>
            <Grid sx={{ m: 0, minHeight: "500px", backgroundColor: "#FFF" }}>
              {data &&
                data.length > 0 &&
                data
                  .filter((a: Appointment) => a.status === 4)
                  .map((a: Appointment, i: number) => {
                    return (
                      <Card sx={{ m: 1, color: "error.dark" }}>
                        <CardContent>
                          <Typography>{`Token: ${a.token}`}</Typography>
                          <Typography>{a.patientName}</Typography>
                          <Typography>{a.phone}</Typography>
                          <Typography>
                            {a.scans ? getScanName(a.scans) : ""}
                          </Typography>
                        </CardContent>
                      </Card>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
        {isLoading && <Loader />}
      </Container>
    </>
  );
}
