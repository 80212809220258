import { Autocomplete, TextField } from "@mui/material";

export interface IAutoComplete {
  t: any;
  options: Array<any>;
  value: any;
  getOptionLabel: (option: any) => void;
  renderOption: (props: any, option: any) => void;
  id?: string;
  field?: any;
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (e: any, obj: any) => void;
  onSearch?: (text: string) => void;
  sx?: any;
}

export default ({
  t,
  options,
  value,
  getOptionLabel,
  renderOption,
  id,
  field,
  label,
  required = false,
  error = false,
  helperText,
  onChange,
  onSearch,
  sx = {},
}: IAutoComplete) => {
  return (
    <Autocomplete
      {...field}
      id={id ? id : "autocomplete-field"}
      sx={sx}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
          required={required}
          error={error}
          helperText={helperText}
          onChange={(e: any) => onSearch && onSearch(e.target.value)}
        />
      )}
      noOptionsText={t("Nooptionsfound")}
      onChange={onChange}
    />
  );
};
