import { memo } from "react";
import { CircularProgress } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { useStyles } from "./styles";

export default memo(() => {
  // const theme: any = useTheme();
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <CircularProgress />
    </div>
  );
});
