import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import { RootState } from "../../pages/store";
import { AUTH_APP_USER } from "../../constants";
import { AppUser } from "../../pages/api";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  // overflow: "hidden",

  backgroundImage: "url(/assets/illustrations/background-frame.jpg)",
  // backgroundRepeat: "repeat-x",
  backgroundPosition: "left",
  backgroundColor: "rgb(255, 255, 255)",
  backgroundSize: "cover",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const storeAppUser = useSelector((state: RootState) => state.appUser);

  useEffect(() => {
    let appUser: AppUser | null = null;
    const storage: string | null = localStorage.getItem(AUTH_APP_USER);
    if (storage && storage.length > 0) {
      try {
        appUser = JSON.parse(storage);
      } catch (ex) {
        appUser = null;
      }
    }
    if (!appUser || !appUser.token || appUser.token.length < 1) {
      navigate("/login", { replace: true });
    }
  }, [storeAppUser]);

  return (
    <StyledRoot>
      <Header openNav={open} onOpenNav={() => setOpen(true)} />

      <Nav open={open} setOpen={() => setOpen(!open)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
