import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  isDesktop: PropTypes.bool,
  open: PropTypes.bool,
};

export default function NavSection({
  data = [],
  isDesktop,
  open,
  ...other
}: any) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item: any) => (
          <NavItem
            key={item.title}
            item={item}
            isDesktop={isDesktop}
            open={open}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isDesktop: PropTypes.bool,
  open: PropTypes.bool,
};

function NavItem({ item, isDesktop, open }: any) {
  const { title, path, icon, info } = item;
  const theme = useTheme();

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.menu",
          bgcolor: "background.menu",
          fontWeight: theme.typography.fontWeightMedium,
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      {(!isDesktop || open) && (
        <ListItemText disableTypography primary={title} />
      )}

      {info && (!isDesktop || open) && info}
    </StyledNavItem>
  );
}
