import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar as MUIToolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  TextField,
  MenuItem,
} from "@mui/material";
import Iconify from "../iconify";
import AutoComplete from "../autoComplete";

const StyledRoot = styled(MUIToolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
  overflow: "auto",
}));

const StyledSearch = styled(OutlinedInput)(({ theme }: any) => ({
  width: 150,
  marginRight: "10px",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export interface IToolbar {
  t: any;
  numSelected: number;
  searchFormFields: Array<any>;
  searchFormValues: any;
  setSearchFormValues: (formValues: any) => void;
  onDeleteSelectedClick: () => void;
}

export default ({
  t,
  numSelected,
  searchFormFields,
  searchFormValues,
  setSearchFormValues,
  onDeleteSelectedClick,
}: IToolbar) => {
  const onFilterChange = (e: any) => {
    setSearchFormValues({
      ...searchFormValues,
      [e.target.name]: [e.target.value],
    });
  };

  return numSelected > 0 ||
    (searchFormFields && searchFormFields.length > 0) ? (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t("selected")}
        </Typography>
      ) : (
        searchFormFields &&
        searchFormFields.length > 0 && (
          <div style={{ display: "inline-flex" }}>
            {searchFormFields.map((ff: any, index: number) => {
              if (ff.type === "autocomplete") {
                return (
                  <AutoComplete
                    t={t}
                    sx={{
                      width: 150,
                      marginRight: "10px",
                    }}
                    options={ff.options}
                    value={ff.options.find(
                      (s: any) => s.id === searchFormValues[ff.name]
                    )}
                    getOptionLabel={(option: any) =>
                      option ? option.name : ""
                    }
                    renderOption={ff.renderOption}
                    label={ff.label}
                    onChange={(e, obj) => {
                      setSearchFormValues({
                        ...searchFormValues,
                        [ff.name]: obj && obj.id ? obj.id : "",
                      });
                    }}
                    onSearch={ff.onSearch}
                  />
                );
              } else if (
                ff.type === "select" &&
                ff.options &&
                ff.options.length > 0
              ) {
                return (
                  <TextField
                    key={"sf-" + index}
                    select={true}
                    name={ff.name}
                    value={searchFormValues[ff.name]}
                    onChange={onFilterChange}
                    // placeholder={ff.label}
                    label={ff.label}
                    title={ff.label}
                    sx={{ width: 150, marginRight: "10px" }}
                  >
                    {ff.options.map((o: any, i: number) => {
                      return (
                        <MenuItem key={o.value} value={o.value}>
                          {o.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                );
              } else {
                return (
                  <StyledSearch
                    key={"sf-" + index}
                    type={ff.type}
                    name={ff.name}
                    value={searchFormValues[ff.name]}
                    onChange={onFilterChange}
                    placeholder={ff.label}
                    title={ff.label}
                    // startAdornment={
                    //   <InputAdornment position="start">
                    //     <Iconify
                    //       icon="eva:search-fill"
                    //       sx={{ color: "text.disabled", width: 20, height: 20 }}
                    //     />
                    //   </InputAdornment>
                    // }
                  />
                );
              }
            })}
          </div>
        )
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDeleteSelectedClick}>
            <Iconify icon="eva:trash-2-fill" sx={{ color: "error.dark" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  ) : null;
};
