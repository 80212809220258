import { emptySplitApi } from "../api/apiSlice";
import {
  Users,
  UserState,
  DraftUser,
  Error,
  FormErrors,
  Message,
  Scans,
  ScanState,
  DraftScan,
} from "../api";

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<Users | Error, string | void>({
      query: (query) => ({
        url: query && query.length ? `/users${query}` : "/users",
        validateStatus: (response, result) => {
          if (result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.users) {
          return [
            "User",
            ...result.users.map(({ id }) => ({ type: "User" as const, id })),
          ];
        } else {
          return ["User"];
        }
      },
    }),
    getUser: builder.query<UserState | Error, string | void>({
      query: (id) => ({
        url: `/users/${id}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // providesTags: (result, error, arg) => [{ type: "User", id: arg }],
    }),
    addNewUser: builder.mutation<UserState | FormErrors | Error, DraftUser>({
      query: (category) => ({
        url: "/users",
        method: "POST",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["User"],
    }),
    editUser: builder.mutation<UserState | FormErrors | Error, DraftUser>({
      query: (category) => ({
        url: "/users",
        method: "PATCH",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    destroyUser: builder.mutation<Message | FormErrors | Error, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["User"],
    }),
    getProfile: builder.query<UserState | FormErrors | Error, string>({
      query: (id) => ({
        url: `/profile/get/${id}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      providesTags: (result, error, arg) => [{ type: "User", id: arg }],
    }),
    editProfile: builder.mutation<any | FormErrors | Error, any>({
      query: (data) => ({
        url: "/profile",
        method: "PATCH",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),
    changePassword: builder.mutation<any | FormErrors | Error, any>({
      query: (data) => ({
        url: "/profile/change-password",
        method: "PATCH",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    }),

    getScans: builder.query<Scans | Error, string | void>({
      query: (query) => ({
        url: query && query.length ? `/scans${query}` : "/scans",
        validateStatus: (response, result) => {
          if (result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.scans) {
          return [
            "Scan",
            ...result.scans.map(({ id }) => ({ type: "Scan" as const, id })),
          ];
        } else {
          return ["Scan"];
        }
      },
    }),
    addNewScan: builder.mutation<ScanState | FormErrors | Error, DraftScan>({
      query: (category) => ({
        url: "/scans",
        method: "POST",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Scan"],
    }),
    editScan: builder.mutation<ScanState | FormErrors | Error, DraftScan>({
      query: (category) => ({
        url: "/scans",
        method: "PATCH",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Scan", id: arg.id }],
    }),
    destroyScan: builder.mutation<Message | FormErrors | Error, string>({
      query: (id) => ({
        url: `/scans/${id}`,
        method: "DELETE",
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Scan"],
    }),

    getapppointmentHours: builder.query<any | Error, string | void>({
      query: (query) => ({
        url:
          query && query.length
            ? `/appointment-hours${query}`
            : "/appointment-hours",
        validateStatus: (response, result) => {
          if (result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.scans) {
          return [
            "AppointmentHours",
            ...result.scans.map(({ id }: any) => ({
              type: "AppointmentHours" as const,
              id,
            })),
          ];
        } else {
          return ["Scan"];
        }
      },
    }),
    editApppointmentHours: builder.mutation<any, any>({
      query: (category) => ({
        url: "/appointment-hours",
        method: "PATCH",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AppointmentHours", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useAddNewUserMutation,
  useEditUserMutation,
  useDestroyUserMutation,
  useGetProfileQuery,
  useEditProfileMutation,
  useChangePasswordMutation,
  useGetScansQuery,
  useAddNewScanMutation,
  useEditScanMutation,
  useDestroyScanMutation,
  useGetapppointmentHoursQuery,
  useEditApppointmentHoursMutation,
} = userApi;
