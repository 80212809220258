import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
// mocks_
import account from "../../../_mock/account";
import { AUTH_APP_USER } from "../../../constants";
import { RootState } from "../../../pages/store";

export default function AccountPopover() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const appUser = useSelector((state: RootState) => state.appUser);

  const MENU_OPTIONS = useMemo(
    () => [
      {
        label: t("Dashboard"),
        icon: "eva:home-fill",
        callback: () => {
          navigate("/dashboard");
        },
      },
      // {
      //   label: t("Profile"),
      //   icon: "eva:person-fill",
      //   callback: () => {
      //     navigate("/profile");
      //   },
      // },
      ...(appUser &&
      appUser.user &&
      appUser?.user?.role.toUpperCase() === "ADMIN"
        ? [
            {
              label: t("Settings"),
              icon: "eva:settings-2-fill",
              callback: () => {
                navigate("/settings");
              },
            },
          ]
        : []),
    ],
    [appUser]
  );

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onLogout = () => {
    // history.push({
    //     pathname: "/"
    // });

    localStorage.removeItem(AUTH_APP_USER);
    window.location.href = "/";
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open
            ? {
                "&:before": {
                  zIndex: 1,
                  content: "''",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  position: "absolute",
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }
            : {}),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {appUser && appUser.user && (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {appUser.user?.name}
            </Typography>
            {appUser.user?.email && (
              <Typography
                variant="body2"
                sx={{ color: "text.secondary" }}
                noWrap
              >
                {appUser.user?.email}
              </Typography>
            )}
          </Box>
        )}

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={option.callback}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={onLogout} sx={{ m: 1 }}>
          {t("Logout")}
        </MenuItem>
      </Popover>
    </>
  );
}
