import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Stack,
  Card,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import { RootState } from "../store";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import {
  useGetAppointmentsQuery,
  useUpdateStatusMutation,
} from "../appointment/slice";
import { fDate, fDateEnd, fDateStart } from "../../utils/formatTime";
import { Appointment, Scan } from "../api";
import { DndContext } from "@dnd-kit/core";
import { Droppable } from "./droppable";
import { Draggable } from "./draggable";
import Loader from "../../components/loader/Loader";
import Iconify from "../../components/iconify/Iconify";
import { useGetScansQuery } from "../user/slice";
import { getScanName } from "../../utils/index";

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appUser = useSelector((state: RootState) => state.appUser);
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const [date, setDate] = useState(new Date());
  const [query, setQuery] = useState(
    `?fromDate=${fDateStart(date)}&toDate=${fDateEnd(
      date
    )}&page=1&limit=all&sort=token asc`
  );

  const scanResult = useGetScansQuery(scanQuery);
  const scanList: any = scanResult.isSuccess
    ? scanResult.data && scanResult.data.scans
      ? scanResult.data.scans
      : []
    : [];

  const result = useGetAppointmentsQuery(query);
  const data: any = result.isSuccess
    ? result.data && result.data.appointments
      ? result.data.appointments
      : null
    : null;

  const [updateStatus] = useUpdateStatusMutation();

  const handleDragEnd = async ({ active, over }: any) => {
    if (
      active &&
      active.id &&
      active.id.length > 0 &&
      active.data &&
      active.data.current &&
      active.data.current.status &&
      over &&
      over.data &&
      over.data.current &&
      over.data.current.status > 0 &&
      active.data.current.status != over.data.current.status
    ) {
      try {
        setMessage(null);
        setIsLoading(true);
        const { appointment, error, invalidData } = await updateStatus({
          id: active.id,
          status: over.data.current.status,
        }).unwrap();
        setIsLoading(false);
        if (appointment) {
          setQuery(query + " ");
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: t("Pleasecorrecterrors") });
        }
      } catch (error: any) {
        setMessage({ type: "error", message: error.message });
      }
    }
  };

  const onChangeDate = (position: number) => {
    let tDate = date;
    if (position === 0) {
      tDate = new Date();
    } else if (position === -1) {
      tDate.setDate(tDate.getDate() - 1);
    } else if (position === 1) {
      tDate.setDate(tDate.getDate() + 1);
    }
    setDate(tDate);
    setQuery(
      `?fromDate=${fDateStart(tDate)}&toDate=${fDateEnd(
        tDate
      )}&page=1&limit=all&sort=token asc`
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Dashboard")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container maxWidth="xl" style={{ maxWidth: "100%", padding: 0 }}>
        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Grid>
          <span>
            <IconButton
              title={t("Previous Day") + ""}
              onClick={() => {
                onChangeDate(-1);
              }}
            >
              <Iconify icon="fa-solid:less-than" />
            </IconButton>
            <Button
              onClick={() => {
                onChangeDate(0);
              }}
              sx={{ color: "#637381" }}
            >
              Today
            </Button>
            <IconButton
              title={t("Next Day") + ""}
              onClick={() => {
                onChangeDate(1);
              }}
            >
              <Iconify icon="fa-solid:greater-than" />
            </IconButton>
          </span>
          <Typography
            sx={{ display: "inline-block", ml: 3, fontWeight: "bold" }}
          >
            {fDate(date)}
          </Typography>
        </Grid>
        <Grid container style={{ overflow: "hidden" }}>
          <DndContext onDragEnd={handleDragEnd}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "warning.dark" }}
              >
                {t("Appointment Confirmed")}
              </Typography>

              <Droppable
                id="1"
                status={1}
                sx={{ borderRight: "1px solid #CCC" }}
              >
                {data &&
                  data.length > 0 &&
                  data
                    .filter((a: Appointment) => a.status === 1)
                    .map((a: Appointment, i: number) => {
                      return (
                        <Draggable
                          key={"apnt-cnf-" + i}
                          id={a.id}
                          token={a.token}
                          status={a.status}
                        >
                          <Card sx={{ m: 1, color: "warning.dark" }}>
                            <CardContent>
                              <Link
                                to={"/appointment/edit/" + a.id}
                                target="_blank"
                                style={{
                                  float: "right",
                                }}
                              >
                                <Iconify
                                  icon={"material-symbols:edit"}
                                  sx={{ color: "warning.dark" }}
                                />
                              </Link>
                              <Typography>{`Token: ${a.token}`}</Typography>
                              <Typography>{a.patientName}</Typography>
                              <Typography>{a.phone}</Typography>
                              <Typography>
                                {a.scans ? getScanName(a.scans) : ""}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Draggable>
                      );
                    })}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "info.dark" }}
              >
                {t("Scan In Progress")}
              </Typography>
              <Droppable
                id="2"
                status={2}
                sx={{ borderRight: "1px solid #CCC" }}
              >
                {data &&
                  data.length > 0 &&
                  data
                    .filter((a: Appointment) => a.status === 2)
                    .map((a: Appointment, i: number) => {
                      return (
                        <Draggable
                          key={"apnt-progress-" + i}
                          id={a.id}
                          token={a.token}
                          status={a.status}
                        >
                          <Card sx={{ m: 1, color: "info.dark" }}>
                            <CardContent>
                              <Link
                                to={"/appointment/edit/" + a.id}
                                target="_blank"
                                style={{
                                  float: "right",
                                }}
                              >
                                <Iconify
                                  icon={"material-symbols:edit"}
                                  sx={{ color: "info.dark" }}
                                />
                              </Link>
                              <Typography>{`Token: ${a.token}`}</Typography>
                              <Typography>{a.patientName}</Typography>
                              <Typography>{a.phone}</Typography>
                              <Typography>
                                {a.scans ? getScanName(a.scans) : ""}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Draggable>
                      );
                    })}
              </Droppable>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "secondary.dark" }}
              >
                {t("Report In Progress")}
              </Typography>
              <Droppable
                id="3"
                status={3}
                sx={{ borderRight: "1px solid #CCC" }}
              >
                {data &&
                  data.length > 0 &&
                  data
                    .filter((a: Appointment) => a.status === 3)
                    .map((a: Appointment, i: number) => {
                      return (
                        <Draggable
                          key={"apnt-rep-progress-" + i}
                          id={a.id}
                          token={a.token}
                          status={a.status}
                        >
                          <Card sx={{ m: 1, color: "secondary.dark" }}>
                            <CardContent>
                              <Link
                                to={"/appointment/edit/" + a.id}
                                target="_blank"
                                style={{
                                  float: "right",
                                }}
                              >
                                <Iconify
                                  icon={"material-symbols:edit"}
                                  sx={{ color: "secondary.dark" }}
                                />
                              </Link>
                              <Typography>{`Token: ${a.token}`}</Typography>
                              <Typography>{a.patientName}</Typography>
                              <Typography>{a.phone}</Typography>
                              <Typography>
                                {a.scans ? getScanName(a.scans) : ""}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Draggable>
                      );
                    })}
              </Droppable>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "error.dark" }}
              >
                {t("Report Ready")}
              </Typography>
              <Droppable id="4" status={4}>
                {data &&
                  data.length > 0 &&
                  data
                    .filter((a: Appointment) => a.status === 4)
                    .map((a: Appointment, i: number) => {
                      return (
                        <Draggable
                          key={"apnt-rpt-ready-" + i}
                          id={a.id}
                          token={a.token}
                          status={a.status}
                        >
                          <Card sx={{ m: 1, color: "error.dark" }}>
                            <CardContent>
                              <Link
                                to={"/appointment/edit/" + a.id}
                                target="_blank"
                                style={{
                                  float: "right",
                                }}
                              >
                                <Iconify
                                  icon={"material-symbols:edit"}
                                  sx={{ color: "error.dark" }}
                                />
                              </Link>
                              <Typography>{`Token: ${a.token}`}</Typography>
                              <Typography>{a.patientName}</Typography>
                              <Typography>{a.phone}</Typography>
                              <Typography>
                                {a.scans ? getScanName(a.scans) : ""}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Draggable>
                      );
                    })}
              </Droppable>
            </Grid>
          </DndContext>
        </Grid>
        {isLoading && <Loader />}
      </Container>
    </>
  );
}
