import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppUser } from "../api";
import { DEFAULT_LANGUAGE } from "../../constants";

const initialState: AppUser = {
  token: "",
  user: null,
  language: DEFAULT_LANGUAGE,
};

export const appUserSlice = createSlice({
  name: "appUser",
  initialState,
  reducers: {
    setAppUser: (state: any, action: PayloadAction<AppUser>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    setLanguage: (state: any, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppUser, setLanguage } = appUserSlice.actions;

export default appUserSlice.reducer;
