import * as Yup from "yup";

export const Schema = (t: any) => {
  return Yup.object().shape({
    phone: Yup.string()
      .typeError(t("Phone Number is required"))
      .required(t("Phone Number is required"))
      .min(10, t("Phone Number must be 10 characters long"))
      .max(10, t("Phone Number must be 10 characters long")),

    patientName: Yup.string()
      .typeError(t("Patient Name is required"))
      .required(t("Patient Name is required"))
      .min(2, t("Patient Name must be at least 2 characters long"))
      .max(100, t("Patient Name must not exceed 100 characters")),

    patientAge: Yup.number()
      .typeError(t("Patient Age is required"))
      .required(t("Patient Age is required"))
      .positive(t("Patient Age must be greater than 0"))
      .integer(t("Patient Age must be an integer")),

    patientGender: Yup.mixed().oneOf(
      ["M", "F", "O"],
      t("Valid Genders are 'Male', 'Female', 'Other'")
    ),

    scanId: Yup.array()
      .typeError(t("Scan Type is required"))
      .required(t("Scan Type is required"))
      .min(1, "Scan Type is required"),

    date: Yup.string()
      .typeError(t("Date is required"))
      .required(t("Date is required")),

    // time: Yup.string()
    //   .typeError(t("Time is required"))
    //   .required(t("Time is required")),
  });
};
