import { Helmet } from "react-helmet-async";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from "@mui/material";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { makeStyles } from "@mui/styles";
import Iconify from "../../components/iconify/Iconify";
import { RootState } from "../store";
import { diffInHours, fDate } from "../../utils/formatTime";
import Label from "../../components/label/Label";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Loader from "../../components/loader/Loader";
import Table from "../../components/table/Table";
import { getTime } from "../../utils";
import { useGetAppointmentQuery } from "../appointment/slice";
import { S3_BUCKET_BASE_URL } from "../../constants";

const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  // justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "3rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default () => {
  const params = useParams();
  const { id } = params;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const language = useSelector((state: RootState) => state.appUser.language);
  const [message, setMessage] = useState<Message | null>(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const result = useGetAppointmentQuery(id + "");
  // const result: any = { isSuccess: true, data: {} };

  useEffect(() => {
    const mn = localStorage.getItem("mobileNumber");
    const isotpverified = localStorage.getItem("isotpverified");
    if (mn && mn.length > 0 && isotpverified && isotpverified === "yes") {
      setMobileNumber(mn);
    }
  }, []);

  const getProgressPercentage = (updatedAt: Date) => {
    let diff = diffInHours(new Date(), updatedAt);
    if (diff > 10) {
      diff = 10;
    } else if (diff < 1) {
      diff = 1;
    }
    return diff * 9.5;
  };

  const onLogout = () => {
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("isotpverified");
    navigate("/mobile");
  };
  return (
    <>
      <Helmet>
        <title>{`${t("View Appointment")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <StyledContent>
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <Card className={classes.root}>
            <CardHeader
              title={
                <Grid sx={{ textAlign: "right" }}>
                  {mobileNumber && mobileNumber.length > 0 && (
                    <span style={{ marginRight: "15px", fontSize: "15px" }}>
                      <span style={{ marginRight: "10px" }}>
                        {mobileNumber}
                      </span>
                      <span
                        onClick={onLogout}
                        style={{
                          cursor: "pointer",
                          color: "#0000ee",
                          textDecoration: "underline",
                        }}
                      >
                        Logout / लॉग आउट
                      </span>
                    </span>
                  )}
                  <span
                    onClick={() => {
                      navigate("/patient/" + result.data.appointment.phone);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#0000ee",
                      textDecoration: "underline",
                      marginRight: "10px",
                      fontSize: "15px",
                    }}
                  >
                    View All / सभी को देखें
                  </span>

                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    sx={{ float: "right" }}
                    onClick={() => {
                      navigate(
                        mobileNumber && mobileNumber.length > 0
                          ? "/appointment"
                          : "/mobile"
                      );
                    }}
                  >
                    {t("New Appointment") + " / नव नियुक्ति"}
                  </Button>
                </Grid>
              }
            />
            <CardContent>
              {result.isSuccess ? (
                result && result.data && result.data.appointment ? (
                  <Grid>
                    <Grid sx={{ mb: 2 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Token / टोकन: {result.data.appointment.token}
                      </Typography>
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "inline-block",
                          mr: 2,
                        }}
                      >
                        {fDate(result.data.appointment.date)}
                      </Typography>

                      <Typography
                        sx={{ fontWeight: "bold", display: "inline-block" }}
                      >
                        {getTime(result.data.appointment.time)}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Label
                        color={
                          result.data.appointment.status === 0
                            ? "default"
                            : result.data.appointment.status === 1
                            ? "warning"
                            : result.data.appointment.status === 2
                            ? "primary"
                            : result.data.appointment.status === 3
                            ? "info"
                            : result.data.appointment.status === 4
                            ? "error"
                            : "success"
                        }
                      >
                        {result.data.appointment.status === 0
                          ? "Draft / मसौदा"
                          : result.data.appointment.status === 1
                          ? "Confirmed / की पुष्टि"
                          : result.data.appointment.status === 2
                          ? "Scan In Progress / स्कैन प्रगति पर है"
                          : result.data.appointment.status === 3
                          ? "Report In Progress / रिपोर्ट प्रगति पर है"
                          : result.data.appointment.status === 4
                          ? "Report Ready / रिपोर्ट तैयार"
                          : "Completed / पुरा होना।"}
                      </Label>
                    </Grid>
                    {(result.data.appointment.status === 3 ||
                      result.data.appointment.status === 4) && (
                      <Grid sx={{ mt: 2, mb: 2 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            result.data.appointment.status === 4
                              ? 100
                              : getProgressPercentage(
                                  new Date(result.data.appointment.updatedAt)
                                )
                          }
                        />
                      </Grid>
                    )}

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Phone Number") + " / फ़ोन नंबर"}:
                      </Typography>
                      <Typography component="span">
                        {result.data.appointment.phone}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Patient Name") + " / रोगी का नाम"}:
                      </Typography>
                      <Typography component="span">
                        {result.data.appointment.patientName}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Patient Age") + " / रोगी की आयु"}:
                      </Typography>
                      <Typography component="span">
                        {result.data.appointment.patientAge}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Patient Gender") + " / रोगी लिंग"}:
                      </Typography>
                      <Typography component="span">
                        {result.data.appointment.patientGender}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="span"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Refered By Doctor") +
                          " / डॉक्टर द्वारा रेफर किया गया"}
                        :
                      </Typography>
                      <Typography component="span">
                        {result.data.appointment.referringDoctorName}
                      </Typography>
                    </Grid>

                    <Grid sx={{ mb: 2 }}>
                      <Typography
                        component="div"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Scans") + " / स्कैन"}:
                      </Typography>
                      {result.data.appointment.scans &&
                      result.data.appointment.scans.length > 0 ? (
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <Table
                              columns={[
                                {
                                  id: "name",
                                  label: t("Scan") + " / स्कैन",
                                  callback: (row: any) => row.scan.name,
                                },
                                {
                                  id: "amount",
                                  label: t("Amount") + " / मात्रा",
                                  callback: (row: any) => row.scan.amount,
                                },
                              ]}
                              data={result.data.appointment.scans}
                              hasPaging={false}
                              minWidth={100}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid>No Scans Found! / कोई स्कैन नहीं मिला!</Grid>
                      )}
                    </Grid>
                    <Grid sx={{ mt: 2 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Report / प्रतिवेदन:
                      </Typography>
                      {result.data.appointment.reports &&
                      result.data.appointment.reports.length > 0 ? (
                        <Grid>
                          {result.data.appointment.reports.map(
                            (f: any, i: number) => (
                              <Grid key={"rpt-" + i}>
                                <a
                                  href={S3_BUCKET_BASE_URL + f.fileName}
                                  target="_blank"
                                >
                                  {f.name}
                                </a>
                              </Grid>
                            )
                          )}
                        </Grid>
                      ) : (
                        <Grid>
                          No reports found!!! / कोई रिपोर्ट नहीं मिली!!!
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>No data found!!! / डाटा प्राप्त नहीं हुआ!!!</Grid>
                )
              ) : (
                <Loader />
              )}
            </CardContent>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
};
