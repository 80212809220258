import { Helmet } from "react-helmet-async";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { sentenceCase } from "change-case";
// @mui
import { Stack, Button, Container, Typography } from "@mui/material";
// components
import Iconify from "../../components/iconify";
// mock
import Table from "../../components/table";
import Label from "../../components/label";
import { fDateTime } from "../../utils/formatTime";
import { RootState } from "../store";
import { useGetUsersQuery, useDestroyUserMutation } from "./slice";
import { Message } from "../../models/message";
import Modal from "../../components/modal";
import Alert from "../../components/alert";
// import Loader from "../../components/loader";

export const List = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state: RootState) => state.appUser.language);
  const [query, setQuery] = useState("?page=1&limit=10&sort=name asc");
  const [message, setMessage] = useState<Message | null>(null);
  const [itemsToDelete, setItemsToDelete] = useState<Array<any>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const result = useGetUsersQuery(query);
  const [destroy] = useDestroyUserMutation();

  const columns = useMemo(
    () => [
      {
        id: "name",
        label: t("Name"),
        link: "/user/edit/:id",
        avatarUrlId: "avatarUrl",
      },
      { id: "userName", label: t("UserName") },
      // { id: "password", label: t("Password") },
      {
        id: "role",
        label: t("Role"),
        callback: (row: any) => (
          <Label
            color={
              row.role === "USER"
                ? "success"
                : row.role === "ADMIN"
                ? "error"
                : row.role === "MANAGER"
                ? "info"
                : row.role === "SUPERVISOR"
                ? "warning"
                : "default"
            }
          >
            {t(row.role === "USER" ? "Worker" : sentenceCase(row.role))}
          </Label>
        ),
      },
      { id: "phone", label: t("Phone") },
      { id: "email", label: t("Email") },
      { id: "address", label: t("Address") },
      {
        id: "createdAt",
        label: t("CreatedAt"),
        callback: (row: any) => fDateTime(row.createdAt),
      },
    ],
    [language]
  );

  const actions = useMemo(
    () => [
      {
        label: t("View"),
        color: "success.dark",
        icon: "carbon:view-filled",
        onClick: (row: any) => {
          navigate(`/user/view/${row.id}`);
        },
      },
      {
        label: t("Edit"),
        color: "info.dark",
        icon: "eva:edit-fill",
        onClick: (row: any) => {
          navigate(`/user/edit/${row.id}`);
        },
      },
      {
        label: t("Delete"),
        color: "error.main",
        icon: "eva:trash-2-outline",
        onClick: (row: any) => {
          setItemsToDelete([row.id]);
        },
      },
    ],
    [language]
  );

  const data: any = result.isSuccess ? (result.data ? result.data : {}) : null;

  useEffect(() => {
    if (result.data?.error) {
      setMessage({ type: "error", message: result.data.error });
    }
  }, [result.data?.error]);

  const handleQuery = useCallback((query: string) => {
    if (query.length) {
      setQuery(query);
    }
  }, []);

  const onDeleteSelected = useCallback(async () => {
    if (itemsToDelete.length) {
      setIsDeleting(true);
      try {
        const { message, error, invalidData } = await destroy(
          itemsToDelete.join()
        ).unwrap();
        setIsDeleting(false);
        setItemsToDelete([]);
        if (message) {
          setMessage({ type: "success", message });
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: invalidData.id });
        }
      } catch (error: any) {
        setIsDeleting(false);
        setItemsToDelete([]);
        setMessage({ type: "error", message: error.message });
      }
    }
  }, [itemsToDelete, destroy]);

  return (
    <>
      <Helmet>
        <title>{`${t("User")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("User")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              navigate("/user/add");
            }}
          >
            {t("NewUser")}
          </Button>
        </Stack>

        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Table
          columns={columns}
          actions={actions}
          data={data && data.users ? data.users : []}
          pagination={data && data.pagination ? data.pagination : null}
          checkboxId="id"
          hasCheckbox={true}
          onDeleteSelected={setItemsToDelete}
          clearSelection={!isDeleting}
          searchFormFields={[
            { name: "name", type: "text", label: t("Name") },
            { name: "phone", type: "text", label: t("Phone") },
            { name: "email", type: "email", label: t("Email") },
          ]}
          searchFormInitialValues={{ name: "", phone: "", email: "" }}
          handleQuery={handleQuery}
          defaultOrderBy="name"
          defaultOrder="asc"
          isLoading={result.isFetching}
        />
      </Container>

      {itemsToDelete && itemsToDelete.length > 0 && (
        <Modal
          title={t("DeleteUser")}
          message={t("SuredeleteUser")}
          okLabel={t("Delete") + ""}
          onOk={onDeleteSelected}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => setItemsToDelete([])}
          isLoading={isDeleting}
          okIcon="eva:trash-2-fill"
          okSX={{
            bgcolor: "error.dark",
            "&:hover": {
              bgcolor: "error.darker",
            },
          }}
        />
      )}
      {/* {result && result.isFetching && <Loader />} */}
    </>
  );
};
