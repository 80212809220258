import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import Iconify from "../iconify";
import Scrollbar from "../scrollbar";
import Head from "./Head";
import Toolbar from "./Toolbar";
import { generateQuery } from "../../utils/generateQuery";
import { useStyles } from "./styles";

export interface ITable {
  title?: string;
  subheader?: string;
  columns: Array<any>;
  data: Array<any>;
  pagination?: {
    count: number;
    currentPage: number;
    limit: number;
    offset: number;
  } | null;
  hasCheckbox?: boolean;
  checkboxId?: string;
  actions?: Array<any>;
  actionValidator?: any;
  onDeleteSelected?: (selectedItems: Array<any>) => void;
  clearSelection?: boolean;
  summaryRow?: Array<any>;
  formProps?: any;
  searchFormFields?: Array<any>;
  searchFormInitialValues?: { [k: string]: string };
  handleQuery?: (query: string) => void;
  defaultOrderBy?: string;
  defaultOrder?: string;
  hasPaging?: boolean;
  isLoading?: boolean;
  aditionalSortOrder?: string;
  minWidth?: number;
}

export default ({
  title,
  subheader,
  columns = [],
  data = [],
  pagination = null,
  hasCheckbox = false,
  checkboxId = "",
  actions = [],
  actionValidator,
  onDeleteSelected,
  clearSelection = false,
  summaryRow = [],
  formProps = null,
  searchFormFields,
  searchFormInitialValues,
  handleQuery,
  defaultOrderBy,
  defaultOrder,
  hasPaging = true,
  isLoading = false,
  aditionalSortOrder = "",
  minWidth = 800,
}: ITable) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const [open, setOpen] = useState(null);
  const [openRow, setopenRow] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState(
    defaultOrderBy && defaultOrderBy.length ? defaultOrderBy : "createdAt"
  );
  const [order, setOrder] = useState(
    defaultOrder && defaultOrder.length ? defaultOrder : "desc"
  );

  const [searchFormValues, setSearchFormValues] = useState<{
    [k: string]: string;
  }>(searchFormInitialValues || {});

  const [selected, setSelected] = useState<Array<any>>([]);

  useEffect(() => {
    if (clearSelection) {
      setSelected([]);
    }
  }, [clearSelection]);

  const handleOpenMenu = (event: any, row: any) => {
    setOpen(event.currentTarget);
    setopenRow(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setopenRow(null);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = data.map((n) => n[checkboxId]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, id: any) => {
    const selectedIndex: any = selected.indexOf(id);
    let newSelected: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    if (handleQuery) {
      const query = generateQuery({
        ...searchFormValues,
        page: page + 1,
        limit: rowsPerPage > 1 ? rowsPerPage.toString() : "all",
        sort: `${orderBy && orderBy.length ? orderBy : "createdAt"} ${
          order && order.length ? order : "desc"
        }${
          aditionalSortOrder && aditionalSortOrder.length > 0
            ? "," + aditionalSortOrder
            : ""
        }`,
      });
      handleQuery(query);
    }
  }, [page, rowsPerPage, orderBy, order, searchFormValues, handleQuery]);

  return (
    <>
      <Card>
        {title && title.length > 0 && (
          <CardHeader title={title} subheader={subheader} sx={{ mb: "15px" }} />
        )}
        <Toolbar
          t={t}
          numSelected={selected.length}
          searchFormFields={searchFormFields ? searchFormFields : []}
          searchFormValues={searchFormValues}
          setSearchFormValues={setSearchFormValues}
          onDeleteSelectedClick={() => {
            if (onDeleteSelected) onDeleteSelected(selected);
          }}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: minWidth, minHeight: 150 }}>
            <Table>
              <Head
                order={order}
                orderBy={orderBy}
                headLabel={columns}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                hasCheckbox={hasCheckbox}
                hasActions={actions && actions.length > 0}
              />
              <TableBody>
                {data.map((row: any, index: number) => {
                  const selectedData = selected.indexOf(row[checkboxId]) !== -1;

                  return (
                    <TableRow
                      hover
                      key={`row-${index}`}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedData}
                    >
                      {hasCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedData}
                            onChange={(event) =>
                              handleClick(event, row[checkboxId])
                            }
                          />
                        </TableCell>
                      )}

                      {columns.map((col: any) => {
                        let link = "";
                        if (col.link && col.link.length > 0) {
                          if (col.link.indexOf(":") >= 0) {
                            const [path, itemId] = col.link.split(":");
                            link = `${path}${row[itemId]}`;
                          } else {
                            link = col.link;
                          }
                        }
                        if (col.callback) {
                          return (
                            <TableCell
                              align={col.alignRight ? "right" : "left"}
                            >
                              {" "}
                              {link && link.length > 0 ? (
                                <Link to={link}>
                                  {col.callback(row, formProps)}
                                </Link>
                              ) : (
                                col.callback(row, formProps)
                              )}
                            </TableCell>
                          );
                        } else {
                          return col.avatarUrlId &&
                            col.avatarUrlId.length > 0 ? (
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={row[col.id]}
                                  src={row[col.avatarUrlId]}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {link && link.length > 0 ? (
                                    <Link to={link}>{row[col.id]}</Link>
                                  ) : (
                                    row[col.id]
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell>
                          ) : (
                            <TableCell
                              align={col.alignRight ? "right" : "left"}
                            >
                              {link && link.length > 0 ? (
                                <Link to={link}>{row[col.id]}</Link>
                              ) : (
                                row[col.id]
                              )}
                            </TableCell>
                          );
                        }
                      })}

                      {actions &&
                        actions.length > 0 &&
                        (!actionValidator ||
                          !actionValidator.validate ||
                          actionValidator.validate(row)) && (
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => handleOpenMenu(e, row)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })}

                {data &&
                  data.length > 0 &&
                  summaryRow &&
                  summaryRow.length > 0 && (
                    <TableRow hover key={"summary-row"} tabIndex={-1}>
                      {hasCheckbox && (
                        <TableCell padding="checkbox"></TableCell>
                      )}

                      {summaryRow.map((col: any) => {
                        return (
                          <TableCell
                            align={col.alignRight ? "right" : "left"}
                            style={col.style ? col.style : {}}
                          >
                            {col.value}
                          </TableCell>
                        );
                      })}

                      {actions && actions.length > 0 && (
                        <TableCell align="right"></TableCell>
                      )}
                    </TableRow>
                  )}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={
                        columns.length +
                        (hasCheckbox ? 1 : 0) +
                        (actions && actions.length > 0 ? 1 : 0)
                      }
                    />
                  </TableRow>
                )} */}
              </TableBody>

              {!isLoading && (!data || !data.length) && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        columns.length +
                        (hasCheckbox ? 1 : 0) +
                        (actions && actions.length > 0 ? 1 : 0)
                      }
                      sx={{ py: 3 }}
                    >
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          {t("Notfound")}
                        </Typography>

                        <Typography variant="body2">
                          {t("Noresultsfoundforthissearch")}
                          <br /> {t("Trycheckingfortypos")}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {isLoading && (
            <div className={styles.container}>
              <CircularProgress />
            </div>
          )}
        </Scrollbar>

        {hasPaging && (
          <TablePagination
            rowsPerPageOptions={[
              { value: 5, label: "5" },
              { value: 10, label: "10" },
              { value: 25, label: "25" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
              // { value: 1, label: t("All") },
            ]}
            component="div"
            count={
              pagination && pagination.count ? pagination.count : data.length
            }
            rowsPerPage={
              rowsPerPage >= 0
                ? rowsPerPage
                : pagination && pagination.count
                ? pagination.count
                : data.length
            }
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("Rowsperpage")}
          />
        )}
      </Card>

      {actions && actions.length > 0 && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          {actions.map((action: any, index: number) => {
            return (
              <MenuItem
                key={`action-${index}`}
                sx={action.color ? { color: action.color } : {}}
                onClick={() => {
                  handleCloseMenu();
                  action.onClick(openRow);
                }}
              >
                <Iconify icon={action.icon ? action.icon : ""} sx={{ mr: 2 }} />
                {action.label}
              </MenuItem>
            );
          })}
        </Popover>
      )}
    </>
  );
};
