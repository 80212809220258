import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import { Card, Button, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import Iconify from "../../components/iconify";
import { Schema } from "./Schema";
import { RootState } from "../store";

export interface IForm {
  initialValues: any;
  onSubmit: (values: any, actions: any) => void;
  isNew?: boolean;
  isLoading?: boolean;
  setHasDelete?: (isDelete: boolean) => void | null;
}

export const Form = ({
  initialValues,
  onSubmit,
  isNew,
  isLoading = false,
  setHasDelete,
}: IForm) => {
  const { t, i18n } = useTranslation();
  const language = useSelector((state: RootState) => state.appUser.language);
  const roleOptions = useMemo(
    () => [
      { value: "ADMIN", label: t("Admin") },
      { value: "MANAGER", label: t("Manager") },
      { value: "SUPERVISOR", label: t("Supervisor") },
      { value: "USER", label: t("Worker") },
    ],
    [language]
  );
  const paymentTermOptions = useMemo(
    () => [
      { value: "hour", label: t("Hour") },
      { value: "day", label: t("Day") },
      { value: "week", label: t("Week") },
      { value: "month", label: t("Month") },
    ],
    [language]
  );

  return (
    <Card sx={{ p: 2 }}>
      <Formik
        enableReinitialize={!isNew}
        validateOnChange={false}
        // validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={Schema(t)}
        onSubmit={onSubmit}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="name"
                render={({ field }: any) => (
                  <TextField
                    name="name"
                    label={t("Name")}
                    type="text"
                    placeholder={"" + t("EnterName")}
                    required={true}
                    autoFocus
                    error={props.errors && props.errors.name}
                    helperText={props.errors && props.errors.name}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="userName"
                render={({ field }: any) => (
                  <TextField
                    name="userName"
                    label={t("UserName")}
                    type="text"
                    placeholder={"" + t("EnterUserName")}
                    required={true}
                    error={props.errors && props.errors.userName}
                    helperText={props.errors && props.errors.userName}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="password"
                render={({ field }: any) => (
                  <TextField
                    name="password"
                    label={t("Password")}
                    type="password"
                    placeholder={"" + t("EnterPassword")}
                    required={true}
                    error={props.errors && props.errors.password}
                    helperText={props.errors && props.errors.password}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="role"
                render={({ field }: any) => (
                  <TextField
                    select={true}
                    name="role"
                    label={t("SelectRole")}
                    required={true}
                    // value={props.values.role}
                    // onChange={handleChange}
                    error={props.errors && props.errors.role}
                    helperText={props.errors && props.errors.role}
                    {...field}
                  >
                    <MenuItem value="">{t("Selectarole")}</MenuItem>
                    {roleOptions &&
                      roleOptions.map((o) => (
                        <MenuItem value={o.value}>{o.label}</MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="phone"
                render={({ field }: any) => (
                  <TextField
                    name="phone"
                    label={t("Phone")}
                    type="text"
                    placeholder={"" + t("Enteruserphonenumber")}
                    required={true}
                    error={props.errors && props.errors.phone}
                    helperText={props.errors && props.errors.phone}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="email"
                render={({ field }: any) => (
                  <TextField
                    name="email"
                    label={t("Email")}
                    type="email"
                    placeholder={"" + t("Enteruseremail")}
                    error={props.errors && props.errors.email}
                    helperText={props.errors && props.errors.email}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ p: 2 }}>
              <Field
                name="address"
                render={({ field }: any) => (
                  <TextField
                    name="address"
                    label={t("Address")}
                    type="text"
                    placeholder={"" + t("EnteranAddressuser")}
                    // required={true}
                    multiline={true}
                    maxRows={4}
                    error={props.errors && props.errors.address}
                    helperText={props.errors && props.errors.address}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <Grid container>
              <Grid item sx={{ width: "50%" }}>
                <FormControl fullWidth sx={{ p: 2 }}>
                  <Field
                    name="paymentRate"
                    render={({ field }: any) => (
                      <TextField
                        name="paymentRate"
                        label={t("PaymentRate")}
                        type="number"
                        required={true}
                        placeholder={"" + t("EnterpaymentRate")}
                        error={props.errors && props.errors.paymentRate}
                        helperText={props.errors && props.errors.paymentRate}
                        onWheel={(e: any) => e.target.blur()}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <FormControl fullWidth sx={{ p: 2 }}>
                  <Field
                    name="paymentTerm"
                    render={({ field }: any) => (
                      <TextField
                        select={true}
                        name="paymentTerm"
                        label={t("SelectPaymentTerm")}
                        required={true}
                        error={props.errors && props.errors.paymentTerm}
                        helperText={props.errors && props.errors.paymentTerm}
                        {...field}
                      >
                        {paymentTermOptions &&
                          paymentTermOptions.map((o) => (
                            <MenuItem value={o.value}>{o.label}</MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              sx={{
                p: 3,
                textAlign: "center",
              }}
            >
              <LoadingButton
                type="submit"
                loading={props.isSubmitting}
                loadingPosition="start"
                startIcon={<Iconify icon="eva:save-fill" />}
                variant="contained"
              >
                {isNew ? t("Add") : t("Update")}
              </LoadingButton>

              {!isNew && setHasDelete && (
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "error.dark",
                    ml: 2,
                    "&:hover": {
                      bgcolor: "error.darker",
                    },
                  }}
                  startIcon={<Iconify icon="eva:trash-2-fill" />}
                  disabled={props.isSubmitting}
                  onClick={() => {
                    setHasDelete(true);
                  }}
                >
                  {t("Delete")}
                </Button>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};
