import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stack, Button, Container, Typography } from "@mui/material";
import Iconify from "../../components/iconify";
import { useAddNewUserMutation } from "./slice";
import { Message } from "../../models/message";
import { Form } from "./Form";
import { DraftUser } from "../api";
import Alert from "../../components/alert/Alert";

export const Add = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<Message | null>(null);
  const [addNew] = useAddNewUserMutation();

  const initialValues: DraftUser = {
    name: "",
    userName: "",
    password: "",
    role: "",
    phone: "",
    email: "",
    address: "",
    paymentRate: "",
    paymentTerm: "hour",
  };

  useEffect(() => {
    if (message?.type && message?.message) {
      window.scrollTo(0, 0);
    }
  }, [message?.type, message?.message]);

  const onSubmit = async (values: any, actions: any) => {
    try {
      if (values.paymentRate === "") {
        values.paymentRate = 0;
      }
      setMessage(null);
      actions.setErrors(null);
      const { user, error, invalidData } = await addNew(values).unwrap();
      actions.setSubmitting(false);
      if (user) {
        const message = {
          type: "success",
          message: t("Usercreatedsuccessfully"),
        };
        navigate("/user", {
          state: { message },
        });
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        actions.setErrors(invalidData);
        setMessage({ type: "error", message: t("Pleasecorrecterrors") });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("User")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("NewUser")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:arrow-back-fill" />}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("Back")}
          </Button>
        </Stack>

        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Form isNew={true} initialValues={initialValues} onSubmit={onSubmit} />
      </Container>
    </>
  );
};
