import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  Avatar,
  CardContent,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../../../components/iconify";
import {
  useGetProfileQuery,
  useEditProfileMutation,
  useChangePasswordMutation,
} from "./../slice";
import { Message } from "../../../models/message";
import { Form } from "./Form";
import { ChangePasswordForm } from "./ChangePasswordForm";
import Alert from "../../../components/alert";
import Loader from "../../../components/loader";
import { RootState } from "../../store";
import SvgColor from "../../../components/svg-color";
import { AUTH_APP_USER } from "../../../constants";
import { setAppUser } from "../../login/appUserSlice";
import Modal from "../../../components/modal";
import { cloneDeep } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const StyledCardMedia = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export const Profile = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appUser = useSelector((state: RootState) => state.appUser);
  const [isEdit, setIsEdit] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [cpMessage, setCPMessage] = useState<Message | null>(null);

  const result = useGetProfileQuery(
    appUser && appUser?.user ? appUser?.user?.id : skipToken
  );
  const [editProfile] = useEditProfileMutation();
  const [changePassword] = useChangePasswordMutation();

  const initialValues = useMemo(() => {
    if (result.isSuccess && result.data.user) {
      return {
        id: result.data.user.id,
        name: result.data.user.name,
        userName: result.data.user.userName,
        phone: result.data.user.phone,
        email: result.data.user.email,
        address: result.data.user.address,
      };
    } else {
      return {
        id: appUser?.user?.id,
        name: "",
        userName: "",
        phone: "",
        email: "",
        address: "",
      };
    }
  }, [result.isSuccess, result.data?.user]);

  const changePasswordInitialValues = {
    id: appUser?.user?.id,
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    if (message?.type && message?.message) {
      window.scrollTo(0, 0);
    }
  }, [message?.type, message?.message]);

  const onSubmit = async (values: any, actions: any) => {
    try {
      setMessage(null);
      actions.setErrors(null);
      const { isUpdated, error, invalidData } = await editProfile(
        values
      ).unwrap();
      actions.setSubmitting(false);
      if (isUpdated) {
        let updatedappUser = cloneDeep(appUser);
        if (updatedappUser && updatedappUser.user) {
          updatedappUser.user.name = values.name;
          updatedappUser.user.userName = values.userName;
          updatedappUser.user.phone = values.phone;
          updatedappUser.user.email = values.email;
          updatedappUser.user.address = values.address;
          localStorage.setItem(AUTH_APP_USER, JSON.stringify(updatedappUser));
          dispatch(setAppUser(updatedappUser));
        }

        // const message = {
        //   type: "success",
        //   message: t("Userupdatedsuccessfully"),
        // };
        result.refetch();
        setIsEdit(false);
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        actions.setErrors(invalidData);
        setMessage({ type: "error", message: t("Pleasecorrecterrors") });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const handleChangePassword = async (values: any, actions: any) => {
    try {
      setMessage(null);
      setCPMessage(null);
      actions.setErrors(null);
      if (values.password === values.confirmPassword) {
        const { isChanged, error, invalidData } = await changePassword(
          values
        ).unwrap();
        actions.setSubmitting(false);
        if (isChanged) {
          setMessage({
            type: "success",
            message: t("PasswordChangedSuccessfully"),
          });
          result.refetch();
          setIsChangePassword(false);
        }
        if (error) {
          setCPMessage({ type: "error", message: error });
        }
        if (invalidData) {
          actions.setErrors(invalidData);
          setCPMessage({ type: "error", message: t("Pleasecorrecterrors") });
        }
      } else {
        setCPMessage({
          type: "error",
          message: t("PasswordAndConfirmPassworddosenotmatch"),
        });
      }
    } catch (error: any) {
      setCPMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Profile")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("Profile")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:arrow-back-fill" />}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("Back")}
          </Button>
        </Stack>

        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card sx={{ position: "relative", width: "500px" }}>
            <StyledCardMedia>
              <SvgColor
                color="paper"
                src="/assets/icons/shape-avatar.svg"
                sx={{
                  width: 80,
                  height: 36,
                  zIndex: 9,
                  bottom: -15,
                  position: "absolute",
                  color: "background.paper",
                }}
              />
              <StyledAvatar
                alt={result?.data?.user?.name}
                src="/assets/avatar_default.jpg"
              />

              <StyledCover
                alt={result?.data?.user?.name}
                src={"./assets/images/covers/cover_4.jpg"}
              />
            </StyledCardMedia>

            <CardContent sx={{ pt: 4 }}>
              {isEdit ? (
                <Form
                  initialValues={initialValues}
                  isLoading={result && result.isFetching}
                  onSubmit={onSubmit}
                  onCancel={() => setIsEdit(false)}
                />
              ) : (
                <>
                  <Typography
                    gutterBottom
                    variant="h3"
                    sx={{ display: "block" }}
                  >
                    {result?.data?.user?.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{ display: "block" }}
                  >
                    {result?.data?.user?.phone}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                    sx={{ color: "text.disabled", display: "block" }}
                  >
                    {result?.data?.user?.email}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="caption"
                    sx={{ color: "text.disabled", display: "block" }}
                  >
                    {result?.data?.user?.address}
                  </Typography>

                  <StyledInfo>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        // ml: 1.5,
                      }}
                      onClick={() => setIsEdit(true)}
                    >
                      <Iconify
                        icon="material-symbols:edit"
                        sx={{ width: 16, height: 16, mr: 0.5 }}
                      />
                      <Typography variant="caption">{t("Edit")}</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        ml: 2,
                      }}
                      onClick={() => setIsChangePassword(true)}
                    >
                      <Iconify
                        icon="solar:lock-password-unlocked-bold"
                        sx={{ width: 16, height: 16, mr: 0.5 }}
                      />
                      <Typography variant="caption">
                        {t("ChangePassword")}
                      </Typography>
                    </Box>
                    {/* <Typography
                      variant="caption"
                      sx={{ cursor: "pointer", mr: 3 }}
                      onClick={() => setIsEdit(true)}
                    >
                      {t("Edit")}
                    </Typography> */}
                    {/* <Typography
                      variant="caption"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setIsChangePassword(true)}
                    >
                      {t("ChangePassword")}
                    </Typography> */}
                  </StyledInfo>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        {isChangePassword && (
          <Modal
            title={t("ChangePassword")}
            onCancel={() => setIsChangePassword(false)}
          >
            <>
              {cpMessage && cpMessage?.message && (
                <Alert message={cpMessage} onClose={() => setCPMessage(null)} />
              )}
              <ChangePasswordForm
                t={t}
                initialValues={changePasswordInitialValues}
                onSubmit={handleChangePassword}
                onCancel={() => {
                  setIsChangePassword(false);
                }}
              />
            </>
          </Modal>
        )}
      </Container>

      {result && result.isFetching && <Loader />}
    </>
  );
};
