import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { AUTH_APP_USER } from "../../constants";
import { RootState } from "../store";
import { AppUser } from ".";

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: any, extraOptions: any) => {
  // wait until the mutex is available without locking it
  // await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions);
  if (
    args &&
    args.url != "/account/login" &&
    result &&
    result.meta &&
    result.meta.response &&
    result.meta.response.status &&
    result.meta.response.status === 401
  ) {
    localStorage.removeItem(AUTH_APP_USER);
    window.location.reload();
  }
  return result;
};

export const emptySplitApi = createApi({
  reducerPath: "api",
  //   baseQuery: fetchBaseQuery({
  //       baseUrl: "/api/v1" ,

  // prepareHeaders: (headers: any, { getState }: any) => {
  //   const state = (getState() as RootState);
  //   if(state && state.appUser && state.appUser.token){
  //     headers.set('authorization', `Bearer ${state.appUser.token}`);
  //     headers.set('user-id', state.appUser.user?.id);
  //   }
  //   // headers.set('authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjYyNTI3MjE3LCJleHAiOjE2NjI2MTM2MTd9.MpvoP2Uy3MnxMNV81cm8-j4yVww6NRuvM2eE_tzFlaw`)
  //   return headers;
  // },
  //   }),
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Appointment", "User", "Scan", "AppointmentHours"],
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});

const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1",

  prepareHeaders: (headers: any, { getState, endpoint }: any) => {
    const uploadEndpoints = ["uploadReport", "uploadPaymentScreenshot"];
    if (!endpoint || uploadEndpoints.indexOf(endpoint) < 0) {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
    }

    const state = getState() as RootState;
    let appUser: AppUser | null = null;
    if (state && state.appUser && state.appUser.token) {
      appUser = state.appUser;
    } else {
      const storage: string | null = localStorage.getItem(AUTH_APP_USER);
      if (storage && storage.length > 0) {
        try {
          appUser = JSON.parse(storage);
        } catch (ex) {
          appUser = null;
        }
      }
    }
    if (appUser && appUser.token && appUser.token.length > 0) {
      headers.set("authorization", `Bearer ${appUser.token}`);
      headers.set("user-id", appUser.user?.id);
    }
    // headers.set('authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjYyNTI3MjE3LCJleHAiOjE2NjI2MTM2MTd9.MpvoP2Uy3MnxMNV81cm8-j4yVww6NRuvM2eE_tzFlaw`)
    return headers;
  },
});
