import * as Yup from "yup";

export const Schema = (t: any) => {
  return Yup.object().shape({
    name: Yup.string()
      .typeError(t("Nameisrequired"))
      .required(t("Nameisrequired"))
      .min(2, t("Namemustbeatleast2characterslong"))
      .max(50, t("Namemustnotexceed50characters")),

    userName: Yup.string()
      .typeError(t("UserNameisrequired"))
      .required(t("UserNameisrequired"))
      .min(2, t("UserNamemustbeatleast2chars"))
      .max(50, t("UserNamemustnotexceed50chars")),

    password: Yup.string()
      .typeError(t("Passwordisrequired"))
      .required(t("Passwordisrequired"))
      .min(2, t("Passwordmustbeatleast2chars"))
      .max(50, t("Passwordmustnotexceed50chars")),

    role: Yup.string()
      .typeError(t("Roleisrequired"))
      .required(t("Roleisrequired"))
      .min(2, t("Rolemustbeatleast2chars"))
      .max(50, t("Rolemustnotexceed50chars")),

    phone: Yup.string()
      .typeError(t("Phonenumberisrequired"))
      .required(t("Phonenumberisrequired"))
      .min(10, t("PhoneNumbermustbe10characterslong"))
      .max(10, t("PhoneNumbermustbe10characterslong")),

    email: Yup.string().email(t("Pleaseprovideavalidemailaddress")),

    address: Yup.string()
      .min(5, t("Addressshouldbeatleast5characterslong"))
      .max(255, t("Addressshouldnotexceed255characters")),

    paymentRate: Yup.number()
      .typeError(t("PaymentRatemustbeanumber"))
      .required(t("PaymentRateisrequired"))
      .positive(t("PaymentRatemustbegreaterthan0"))
      .moreThan(0, t("PaymentRatemustbegreaterthan0"))
      .test(
        "maxTwoDecimalPoints",
        t("paymentRateatmost2decimalpoint"),
        // @ts-ignore
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      ),

    paymentTerm: Yup.mixed().oneOf(
      ["hour", "day", "week", "month"],
      t("ValidPaymentTermsare")
    ),
  });
};
