import { emptySplitApi } from "../api/apiSlice";
import {
  AppointmentState,
  DraftAppointment,
  Error,
  FormErrors,
  Message,
} from "../api";

export const appointmentApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    createPatient: builder.mutation<any, any>({
      query: (data) => ({
        url: "/patient",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    verifyOTP: builder.mutation<any, any>({
      query: (data) => ({
        url: "/patient/verify",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    resendOTP: builder.mutation<any, any>({
      query: (data) => ({
        url: "/patient/resend",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    newAppointment: builder.mutation<
      AppointmentState | FormErrors | Error,
      DraftAppointment
    >({
      query: (data) => ({
        url: "/appointment",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Appointment"],
    }),
    getTime: builder.query<any, any>({
      query: (query) => ({
        url: `/appointment-hours/get-time/${query}`,
        validateStatus: (response, result) => {
          if (result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
    uploadPaymentScreenshot: builder.mutation<any, any>({
      query: (data) => ({
        url: `/payment/upload/${data.id}?fileName=${data.fileName}&date=${data.date}&isAdvancePaid=${data.isAdvancePaid}&isTotalAmountPaid=${data.isTotalAmountPaid}&paymentId=${data.paymentId}`,
        method: "POST",
        // headers: {
        //   "content-type": "multipart/form-data;",
        // },
        body: data.data,
        formData: true,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    createPayment: builder.mutation<any, any>({
      query: (data) => ({
        url: "/appointment/create-payment",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    paymentSuccess: builder.mutation<any, any>({
      query: (data) => ({
        url: "/appointment/update-payment-info",
        method: "POST",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Appointment"],
    }),
    getAppointmentsByPhone: builder.query<
      AppointmentState | Error,
      string | void
    >({
      query: (query) => ({
        url: `/appointment/get-by-phone/${query}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
    getAppointments: builder.query<any, any>({
      query: (query) => ({
        url: `/appointment${query}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
    getAllAppointments: builder.query<any, any>({
      query: (query) => ({
        url: `/appointment${query}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
    updateStatus: builder.mutation<any, any>({
      query: (data) => ({
        url: "/appointment/update-status",
        method: "PATCH",
        body: data,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Appointment"],
    }),

    getAppointment: builder.query<any, any>({
      query: (id) => ({
        url: `/appointment/get/${id}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // providesTags: (result, error, arg) => [{ type: "User", id: arg }],
    }),
    editAppointment: builder.mutation<
      AppointmentState | FormErrors | Error,
      DraftAppointment
    >({
      query: (category) => ({
        url: "/appointment",
        method: "PATCH",
        body: category,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Appointment", id: arg.id },
      ],
    }),
    destroyAppointment: builder.mutation<Message | FormErrors | Error, string>({
      query: (id) => ({
        url: `/appointment/delete/${id}`,
        method: "DELETE",
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      invalidatesTags: ["Appointment"],
    }),
    uploadReport: builder.mutation<any, any>({
      query: (data) => ({
        url: `/report/upload/${data.id}?name=${data.name}&fileName=${data.fileName}`,
        method: "POST",
        // headers: {
        //   "content-type": "multipart/form-data;",
        // },
        body: data.data,
        formData: true,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
      // invalidatesTags: ["Appointment"],
    }),
    destroyReport: builder.mutation<Message | FormErrors | Error, string>({
      query: (id) => ({
        url: `/appointment/delete-report/${id}`,
        method: "DELETE",
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
    getPatientsByPhone: builder.query<any | Error, string | void>({
      query: (query) => ({
        url: `/appointment/get-patient-by-phone/${query}`,
        validateStatus: (response, result) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),
    }),
  }),
});

export const {
  useNewAppointmentMutation,
  useCreatePatientMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  useLazyGetTimeQuery,
  useUploadPaymentScreenshotMutation,
  useCreatePaymentMutation,
  usePaymentSuccessMutation,
  useGetAppointmentsByPhoneQuery,
  useGetAppointmentsQuery,
  useLazyGetAllAppointmentsQuery,
  useUpdateStatusMutation,
  useGetAppointmentQuery,
  useEditAppointmentMutation,
  useDestroyAppointmentMutation,
  useUploadReportMutation,
  useDestroyReportMutation,
  useLazyGetPatientsByPhoneQuery,
} = appointmentApi;
