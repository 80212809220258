import { Helmet } from "react-helmet-async";
import { useMemo, useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  TextField,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { makeStyles } from "@mui/styles";
import { Schema } from "./Schema";
import Iconify from "../../components/iconify/Iconify";
import { DraftAppointment } from "../api";
import {
  useNewAppointmentMutation,
  useLazyGetTimeQuery,
  // useCreatePaymentMutation,
  useUploadPaymentScreenshotMutation,
} from "./slice";
import { useGetScansQuery } from "../user/slice";
import { currentDate, fDate, isFutureDate } from "../../utils/formatTime";
import Label from "../../components/label/Label";
import Table from "../../components/table/Table";
import { getTime as utilGetTime } from "../../utils";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    width: "100%",
    margin: "3rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

export default () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // let [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const [message, setMessage] = useState<Message | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const [timeList, setTimeList] = useState([]);
  const [apt, setApt] = useState<any>(null);
  const [paymentType, setPaymentType] = useState("advance");
  const [paymentId, setPaymentId] = useState(new Date().getTime());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const scanResult = useGetScansQuery(scanQuery);
  const scanList: any = scanResult.isSuccess
    ? scanResult.data && scanResult.data.scans
      ? scanResult.data.scans.filter((s) => s.isActive)
      : []
    : [];

  const [addNew] = useNewAppointmentMutation();
  const [getTime, { error }] = useLazyGetTimeQuery();
  // const [pay] = useCreatePaymentMutation();
  const [uploadPaymentScreenshot] = useUploadPaymentScreenshotMutation();

  const genderOptions = useMemo(
    () => [
      { value: "M", label: t("Male") + " / पुरुष" },
      { value: "F", label: t("Female") + " / महिला" },
      { value: "O", label: t("Other") + " / अन्य" },
    ],
    []
  );

  useEffect(() => {
    // if (searchParams && searchParams.get("isPaymentFailed") === "true") {
    //   setMessage({
    //     type: "error",
    //     message:
    //       "Payment failed. Please try again. / भुगतान विफल रही। कृपया पुन: प्रयास करें।.",
    //   });
    // }

    const mn = localStorage.getItem("mobileNumber");
    const isotpverified = localStorage.getItem("isotpverified");
    if (mn && mn.length > 0 && isotpverified && isotpverified === "yes") {
      setMobileNumber(mn);
      getAvailableTimes(currentDate("yyyy-MM-dd"));
    } else {
      navigate("/mobile");
    }

    const tapts = localStorage.getItem("appointment");
    if (tapts && tapts.length > 0) {
      try {
        const tapt = JSON.parse(tapts);
        if (tapt && tapt.id) {
          setApt(tapt);
        }
      } catch (e: any) {}
    }

    const today = new Date().toJSON().slice(0, 10);
    const date = document.getElementById("date-picker");
    date?.setAttribute("min", today);
  }, []);

  const initialValues: DraftAppointment = {
    phone: mobileNumber,
    patientName: "",
    patientAge: "",
    patientGender: "M",
    referringDoctorName: "",
    scanId: [],
    date: currentDate("yyyy-MM-dd"),
    time: "",
    isSubmitted: false,
  } as unknown as DraftAppointment;

  const getAvailableTimes = async (date: string) => {
    if (date && date.length > 0 && isFutureDate(new Date(date))) {
      setIsLoading(true);
      const { availableTimes, error, invalidData } = await getTime(
        date
      ).unwrap();
      setIsLoading(false);
      if (availableTimes && availableTimes.length > 0) {
        setTimeList(availableTimes);
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
    } else {
      setTimeList([]);
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    try {
      if (values.scanId && values.scanId.length > 0) {
        const scans = [...values.scanId];
        values.phone = mobileNumber;
        if (values.patientAge === "") {
          values.patientAge = 0;
        } else {
          values.patientAge = parseInt(values.patientAge);
        }
        values.advanceAmount = 100;
        let totalAmount = 0;
        values.scanId.map((s: any) => {
          totalAmount += s.amount;
        });
        values.totalAmount = totalAmount;
        // values.scanId = values.scanId.map((s: any) => s.id);
        setMessage(null);
        actions.setErrors(null);
        const { appointment, error, invalidData } = await addNew({
          ...values,
          scanId: values.scanId.map((s: any) => s.id),
          isAdvancePaid: false,
          isTotalAmountPaid: false,
        }).unwrap();
        actions.setSubmitting(false);
        if (appointment) {
          // const message = {
          //   type: "success",
          //   message: t("Apponitmentcreatedsuccessfully"),
          // };
          let tmpApt: any = { ...appointment, scans };
          // if (
          //   (!tmpApt.paymentId || tmpApt.paymentId.length < 1) &&
          //   tmpApt.id &&
          //   tmpApt.id.length > 0
          // ) {
          //   tmpApt.paymentId = tmpApt.id;
          // }
          localStorage.setItem("appointment", JSON.stringify(tmpApt));
          setApt(tmpApt);
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          actions.setErrors(invalidData);
          setMessage({
            type: "error",
            message: t("Pleasecorrecterrors") + "/ कृपया त्रुटियाँ सुधारें.",
          });
        }
      } else {
        setMessage({
          type: "error",
          message: "Please select the Scan. / कृपया स्कैन का चयन करें.",
        });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  /*
  const onPay = async () => {
    if (paymentType && paymentType.length > 0) {
      try {
        setMessage(null);
        setIsPaying(true);

        let tmpApt = { ...apt, paymentType };
        localStorage.setItem("appointment", JSON.stringify(tmpApt));
        setApt(tmpApt);

        const baseURL = window.location.origin;
        let payload = {
          purpose: "Book appointment in Rani Basant Diagnostic Centre.",
          amount:
            paymentType === "advance" ? apt.advanceAmount : apt.totalAmount,
          buyer_name: apt.patientName,
          phone: apt.phone,
          // webhook: baseURL,
          redirect_url: baseURL + "/payment-success",
        };

        const { response, error, invalidData } = await pay(payload).unwrap();
        setIsPaying(false);
        if (response && response.success && response.payment_request) {
          window.location.replace(response.payment_request.longurl);
        } else if (response && response.message) {
          let message = "";
          for (const key in response.message) {
            message +=
              message.length > 0 ? ", " : "" + response.message[key].join(",");
          }
          if (message && message.length > 0) {
            setMessage({ type: "error", message: message });
          }
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({
            type: "error",
            message: t("Pleasecorrecterrors") + " / कृपया त्रुटियाँ सुधारें.",
          });
        }
      } catch (error: any) {
        setMessage({ type: "error", message: error.message });
      }
    } else {
      setMessage({
        type: "error",
        message: "Please select a payment type. / कृपया भुगतान प्रकार चुनें.",
      });
    }
  };*/

  const onUploadScreenshot = (e: any) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const onFileSelect = async (e: any) => {
    try {
      const selectedFile = e.target.files[0];
      if (selectedFile && selectedFile.name && selectedFile.name.length > 0) {
        const nameArr = selectedFile.name.split(".");
        const fileName =
          nameArr && nameArr.length > 1
            ? "pay_" + apt.id + "-" + new Date().getTime() + "." + nameArr[1]
            : selectedFile.name;
        setMessage(null);
        setIsPaying(true);
        let form = new FormData();
        form.append("file", selectedFile, fileName);
        const { appointment, error, invalidData } =
          await uploadPaymentScreenshot({
            id: apt.id,
            fileName,
            date: apt.date,
            isAdvancePaid: paymentType === "advance",
            isTotalAmountPaid: paymentType === "full",
            paymentId: paymentId,
            data: form,
          }).unwrap();
        setIsPaying(false);
        if (appointment) {
          let scans = appointment.scans.map((s: any) => s.scan);
          let tmpApt = { ...appointment, scans };
          localStorage.setItem("appointment", JSON.stringify(tmpApt));
          setApt(tmpApt);

          setMessage({
            type: "success",
            message:
              t("Apponitment created successfully.") +
              " / नियुक्ति सफलतापूर्वक बनाई गई.",
          });
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: t("Pleasecorrecterrors") });
        }
      } else {
        if (selectedFile && selectedFile.name && selectedFile.name.length > 0) {
          setMessage({
            type: "error",
            message: t("Please select the file to upload."),
          });
        }
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const onOk = () => {
    // localStorage.removeItem("mobileNumber");
    // localStorage.removeItem("isotpverified");
    localStorage.removeItem("appointment");
    navigate("/appointment/view/" + apt.id);
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Appointment")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <Card className={classes.root}>
            <CardHeader
              title={
                apt && apt.id ? (
                  apt.isAdvancePaid || apt.isTotalAmountPaid ? (
                    <Grid
                      sx={{
                        color: "success.dark",
                      }}
                    >
                      <Iconify icon="teenyicons:tick-circle-solid" width={30} />
                      <Typography variant="h5" component="h2">
                        {t("Appointment Successfully Booked") +
                          " / अपॉइंटमेंट सफलतापूर्वक बुक हो गया"}
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography variant="h5" component="h2">
                      {t("Payment") + " / भुगतान"}
                    </Typography>
                  )
                ) : (
                  <Typography variant="h5" component="h2">
                    {t("Appointment") + " / नियुक्ति"}
                  </Typography>
                )
              }
            />
            <CardContent>
              {apt && apt.id ? (
                <Grid>
                  {apt.isAdvancePaid || apt.isTotalAmountPaid ? (
                    <Grid>
                      <Grid sx={{ color: "primary.main", mb: 3 }}>
                        <Typography
                          sx={{
                            display: "inline-block",
                            mr: 4,
                            fontWeight: "bold",
                          }}
                        >
                          Token Number / टोकन नंबर
                        </Typography>
                        <Typography
                          sx={{
                            display: "inline-block",
                            fontWeight: "bold",
                            fontSize: "25px",
                          }}
                        >
                          {apt.token}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ display: "inline-block", mr: 2 }}>
                          {apt.scans &&
                            apt.scans.map((s: any) => s.name).join(", ")}
                        </Typography>
                        <Typography sx={{ display: "inline-block", mr: 2 }}>
                          -
                        </Typography>
                        <Typography sx={{ display: "inline-block", mr: 2 }}>
                          {fDate(apt.date)}
                        </Typography>
                        <Typography sx={{ display: "inline-block", mr: 2 }}>
                          -
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {utilGetTime(apt.time)}
                        </Typography>
                      </Grid>

                      <Grid
                        sx={{
                          p: 3,
                          mt: 4,
                          textAlign: "center",
                        }}
                      >
                        <LoadingButton
                          type="button"
                          loading={isPaying}
                          loadingPosition="start"
                          // startIcon={<Iconify icon="eva:save-fill" />}
                          variant="contained"
                          fullWidth
                          size="large"
                          onClick={onOk}
                        >
                          {t("Ok") + " / ठीक है"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid>
                      {apt.scans && apt.scans.length > 0 && (
                        <Table
                          columns={[
                            {
                              id: "name",
                              label: t("Scan") + " / स्कैन",
                            },
                            {
                              id: "amount",
                              label: t("Amount") + " / मात्रा",
                            },
                          ]}
                          data={apt.scans}
                          hasPaging={false}
                          minWidth={100}
                        />
                      )}

                      <FormControl sx={{ mt: 3 }}>
                        <RadioGroup
                          row
                          aria-labelledby="payment-type"
                          name="payment-type"
                          value={paymentType}
                          onChange={(e) => {
                            setPaymentType(e.target.value);
                          }}
                        >
                          <Grid>
                            <FormControlLabel
                              value="advance"
                              control={<Radio />}
                              label={
                                apt.advanceAmount +
                                " (Booking Confirmation / बुकिंग की पुष्टि)"
                              }
                            />
                          </Grid>
                          <Grid>
                            <FormControlLabel
                              value="full"
                              control={<Radio />}
                              label={
                                apt.totalAmount +
                                " (Full Payment / पूर्ण भुगतान)"
                              }
                            />
                          </Grid>
                        </RadioGroup>
                      </FormControl>

                      <Grid
                        sx={{
                          p: 3,
                          textAlign: "center",
                        }}
                      >
                        Pay the amount by pressing Pay button and take a
                        screenshot after the payment success. Upload the
                        screenshot by pressing Upload screenshot button. / पे
                        बटन दबाकर राशि का भुगतान करें और ए भुगतान सफलता के बाद
                        स्क्रीनशॉट। अपलोड करें अपलोड स्क्रीनशॉट बटन दबाकर
                        स्क्रीनशॉट।.
                      </Grid>
                      <Grid
                        sx={{
                          p: 3,
                          textAlign: "center",
                        }}
                      >
                        <a
                          href={`upi://pay?pa=11192087908@okbizaxis&pn=Rani Basant Diagnostic Centre&mc=8011&tr=${paymentId}&tn=Payment for scan&cu=INR&am=${
                            paymentType === "advance"
                              ? apt.advanceAmount
                              : apt.totalAmount
                          }&url=https://www.booking.ranibasant.com/appointment`}
                        >
                          <LoadingButton
                            type="button"
                            loading={isPaying}
                            loadingPosition="start"
                            // startIcon={<Iconify icon="eva:save-fill" />}
                            variant="contained"
                            // fullWidth
                            size="large"
                            // onClick={onPay}
                            style={{
                              marginTop: "15px",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            {t("Pay") + " / वेतन"}
                          </LoadingButton>
                        </a>

                        <LoadingButton
                          type="button"
                          loading={isPaying}
                          loadingPosition="start"
                          // startIcon={<Iconify icon="eva:save-fill" />}
                          variant="contained"
                          // fullWidth
                          size="large"
                          onClick={onUploadScreenshot}
                          style={{
                            marginTop: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {t("Upload Screenshot") + " / स्क्रीनशॉट अपलोड करें"}
                        </LoadingButton>

                        <LoadingButton
                          type="button"
                          loading={false}
                          loadingPosition="start"
                          variant="contained"
                          // fullWidth
                          size="large"
                          onClick={() => {
                            localStorage.removeItem("appointment");
                            setApt(null);
                          }}
                          style={{
                            marginTop: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                            backgroundColor: "#9f3333",
                          }}
                        >
                          {t("Cancel") + " / रद्द करना"}
                        </LoadingButton>
                        <input
                          type="file"
                          onChange={onFileSelect}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Formik
                  enableReinitialize={false}
                  validateOnChange={false}
                  // validateOnBlur={false}
                  initialValues={initialValues}
                  validationSchema={Schema(t)}
                  onSubmit={onSubmit}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="patientName"
                          render={({ field }: any) => (
                            <TextField
                              name="patientName"
                              label={t("Patient Name") + " / रोगी का नाम"}
                              type="text"
                              placeholder={
                                "" + t("Patient Name") + " / रोगी का नाम"
                              }
                              required={true}
                              autoFocus
                              error={
                                isSubmitted &&
                                props.errors &&
                                props.errors.patientName
                              }
                              helperText={
                                isSubmitted && props.errors
                                  ? props.errors.patientName
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="patientAge"
                          render={({ field }: any) => (
                            <TextField
                              name="patientAge"
                              label={t("Patient Age") + " / रोगी की आयु"}
                              type="number"
                              placeholder={
                                "" + t("Patient Age") + " / रोगी की आयु"
                              }
                              required={true}
                              error={
                                isSubmitted &&
                                props.errors &&
                                props.errors.patientAge
                              }
                              helperText={
                                isSubmitted &&
                                props.errors &&
                                props.errors.patientAge
                              }
                              onWheel={(e: any) => e.target.blur()}
                              {...field}
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="patientGender"
                          render={({ field }: any) => (
                            <TextField
                              select={true}
                              name="patientGender"
                              label={t("Patient Gender") + " / रोगी लिंग"}
                              required={true}
                              error={
                                isSubmitted &&
                                props.errors &&
                                props.errors.patientGender
                              }
                              helperText={
                                isSubmitted && props.errors
                                  ? props.errors.patientGender
                                  : ""
                              }
                              {...field}
                              sx={{ textAlign: "left" }}
                            >
                              {genderOptions.map((o: any, i: number) => (
                                <MenuItem key={"ptopt" + i} value={o.value}>
                                  {o.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="referringDoctorName"
                          render={({ field }: any) => (
                            <TextField
                              name="referringDoctorName"
                              label={
                                t("Refered By Doctor") +
                                " / डॉक्टर द्वारा रेफर किया गया"
                              }
                              type="text"
                              placeholder={
                                "" +
                                t("Refered By Doctor") +
                                " / डॉक्टर द्वारा रेफर किया गया"
                              }
                              error={
                                isSubmitted &&
                                props.errors &&
                                props.errors.referringDoctorName
                              }
                              helperText={
                                isSubmitted && props.errors
                                  ? props.errors.referringDoctorName
                                  : ""
                              }
                              {...field}
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="scanId"
                          render={({ field }: any) => (
                            <Autocomplete
                              // {...field}
                              multiple
                              limitTags={2}
                              id="scanId"
                              options={scanList}
                              getOptionLabel={(option: any) => option.name}
                              defaultValue={props.values.scanId}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Scan / स्कैन"
                                  placeholder="Select Scan / स्कैन का चयन करें"
                                />
                              )}
                              // sx={{ width: "500px" }}
                              onChange={(e: any, newValue: any) => {
                                // console.log(newValue);
                                props.values.scanId = newValue;
                                props.handleChange(e);
                              }}
                            />
                          )}
                        />
                        {isSubmitted && props.errors && props.errors.scanId && (
                          <div
                            style={{
                              lineHeight: "1.5",
                              fontSize: "0.75rem",
                              fontWeight: "400",
                              textAlign: "left",
                              marginTop: "3px",
                              marginLeft: "14px",
                              color: "#FF4842",
                            }}
                          >
                            {props.errors.scanId}
                          </div>
                        )}
                      </FormControl>

                      <FormControl fullWidth sx={{ p: 2 }}>
                        <Field
                          name="date"
                          render={({ field }: any) => (
                            <TextField
                              name="date"
                              id="date-picker"
                              label={t("Date") + " / तारीख"}
                              type="date"
                              placeholder={"" + t("Date") + " / तारीख"}
                              required={true}
                              // max={new Date().toISOString().split("T")[0]}
                              error={
                                isSubmitted && props.errors && props.errors.date
                              }
                              helperText={
                                isSubmitted && props.errors
                                  ? props.errors.date
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...field}
                              onChange={(e: any) => {
                                getAvailableTimes(e.target.value);
                                props.handleChange(e);
                              }}
                            />
                          )}
                        />
                      </FormControl>

                      <Grid
                        sx={{
                          p: 3,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{ textAlign: "left", fontWeight: "bold" }}
                        >
                          Time / समय:
                        </Typography>
                        {!isLoading && timeList && timeList.length > 0 ? (
                          <Grid sx={{ textAlign: "left" }}>
                            {timeList.map((time: any, i: number) => (
                              <Label
                                key={"time" + i}
                                color={
                                  props.values.time === time.value
                                    ? "error"
                                    : "default"
                                }
                                onClick={(e: any) => {
                                  props.values.time = time.value;
                                  props.handleChange(e);
                                  setSelectedTime(time.value);
                                }}
                                sx={{ mx: 2, my: 1, cursor: "pointer" }}
                              >
                                {time.label}
                              </Label>
                            ))}
                          </Grid>
                        ) : isLoading ? (
                          <CircularProgress />
                        ) : (
                          <Grid sx={{ textAlign: "left" }}>
                            {t(
                              "No time slot found for the selected date. / चयनित तिथि के लिए कोई समय स्लॉट नहीं मिला."
                            )}
                          </Grid>
                        )}
                      </Grid>
                      {isSubmitted && props.errors && props.errors.time && (
                        <Grid
                          sx={{
                            color: "#FF4842",
                            lineHeight: "1.5",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            textAlign: "left",
                            marginRight: "14px",
                            marginLeft: "14px",
                          }}
                        >
                          {props.errors.time}
                        </Grid>
                      )}

                      <Grid
                        sx={{
                          p: 3,
                          textAlign: "center",
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          loading={props.isSubmitting || isLoading}
                          loadingPosition="start"
                          startIcon={<Iconify icon="eva:save-fill" />}
                          variant="contained"
                          // fullWidth
                          size="large"
                          onClick={() => {
                            setIsSubmitted(true);
                          }}
                          style={{
                            marginTop: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {t("Submit") + " / जमा करना"}
                        </LoadingButton>

                        <LoadingButton
                          type="button"
                          loading={false}
                          loadingPosition="start"
                          variant="contained"
                          // fullWidth
                          size="large"
                          onClick={() => {
                            localStorage.removeItem("appointment");
                            localStorage.removeItem("mobileNumber");
                            localStorage.removeItem("isotpverified");
                            setMobileNumber("");
                            setApt(null);
                            navigate("/mobile");
                          }}
                          style={{
                            marginTop: "15px",
                            marginLeft: "5px",
                            marginRight: "5px",
                            backgroundColor: "#9f3333",
                          }}
                        >
                          {t("Cancel") + " / रद्द करना"}
                        </LoadingButton>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </CardContent>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
};
