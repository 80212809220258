import * as Yup from "yup";

export const Schema = (t: any) => {
  return Yup.object().shape({
    userName: Yup.string()
      .typeError(t("UserNameisrequired"))
      .required(t("UserNameisrequired"))
      .min(2, t("UserNamemustbeatleast2chars"))
      .max(50, t("UserNamemustnotexceed50chars")),

    password: Yup.string()
      .typeError(t("Passwordisrequired"))
      .required(t("Passwordisrequired"))
      .min(8, t("Passwordmustbeatleast2chars"))
      .max(50, t("Passwordmustnotexceed50chars")),
  });
};
