import { Helmet } from "react-helmet-async";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { makeStyles } from "@mui/styles";
import { useGetAppointmentsByPhoneQuery } from "../appointment/slice";
import { RootState } from "../store";
import { diffInHours, fDate, fDateTime } from "../../utils/formatTime";
import Label from "../../components/label/Label";
import Table from "../../components/table/Table";
import Iconify from "../../components/iconify/Iconify";
// import { useGetScansQuery } from "../user/slice";
import { getScanName, getTime } from "../../utils";
import { S3_BUCKET_BASE_URL } from "../../constants";

const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "3rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default () => {
  const params = useParams();
  const { phone } = params;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const language = useSelector((state: RootState) => state.appUser.language);
  const [message, setMessage] = useState<Message | null>(null);
  const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const [query, setQuery] = useState(`${phone}?page=1&limit=10&sort=date desc`);
  const [mobileNumber, setMobileNumber] = useState("");

  // const scanResult = useGetScansQuery(scanQuery);
  // const scanList: any = scanResult.isSuccess
  //   ? scanResult.data && scanResult.data.scans
  //     ? scanResult.data.scans
  //     : []
  //   : [];
  const result = useGetAppointmentsByPhoneQuery(query);
  const data: any = result.isSuccess ? (result.data ? result.data : {}) : null;

  const columns = useMemo(
    () => [
      {
        id: "date",
        label: t("Date") + " / तारीख",
        callback: (row: any) => fDate(row.date),
      },
      {
        id: "time",
        label: t("Time") + " / समय",
        callback: (row: any) => getTime(row.time),
      },
      {
        id: "scanId",
        label: t("Scan") + " / स्कैन",
        callback: (row: any) => {
          return getScanName(row.scans);
        },
      },
      {
        id: "patientName",
        label: t("Patient Name") + " / रोगी का नाम",
        // avatarUrlId: "avatarUrl",
      },
      { id: "patientAge", label: t("Patient Age") + " / रोगी की आयु" },
      { id: "patientGender", label: t("Patient Gender") + " / रोगी लिंग" },
      {
        id: "referringDoctorName",
        label: t("Refered By") + " / द्वारा उल्लिखित",
      },
      {
        id: "token",
        label: t("Token") + " / टोकन",
        callback: (row: any) => (
          <Link to={"/appointment/view/" + row.id}>{row.token}</Link>
        ),
      },
      {
        id: "status",
        label: t("Status") + " / स्थिति",
        callback: (row: any) => (
          <Label
            color={
              row.status === 0
                ? "default"
                : row.status === 1
                ? "warning"
                : row.status === 2
                ? "primary"
                : row.status === 3
                ? "info"
                : row.status === 4
                ? "error"
                : "success"
            }
          >
            {row.status === 0
              ? "Draft / मसौदा"
              : row.status === 1
              ? "Confirmed / की पुष्टि"
              : row.status === 2
              ? "Scan In Progress / स्कैन प्रगति पर है"
              : row.status === 3
              ? "Report In Progress / रिपोर्ट प्रगति पर है"
              : row.status === 4
              ? "Report Ready / रिपोर्ट तैयार"
              : "Completed / पुरा होना।"}
          </Label>
        ),
      },
      {
        id: "report",
        label: t("Report") + " / प्रतिवेदन",
        callback: (row: any) => {
          if (row.reports && row.reports.length > 0) {
            return (
              <Grid>
                {row.reports.map((f: any, i: number) => (
                  <Grid key={"rpt-" + i}>
                    <a href={S3_BUCKET_BASE_URL + f.fileName} target="_blank">
                      {f.name}
                    </a>
                  </Grid>
                ))}
              </Grid>
            );
          } else {
            return "";
          }
        },
      },
      {
        id: "createdAt",
        label: t("CreatedAt") + " / पर बनाया गया",
        callback: (row: any) => fDateTime(row.createdAt),
      },
      {
        id: "id",
        label: t("View") + " / देखना",
        callback: (row: any) => (
          <Link to={"/appointment/view/" + row.id}>
            <Iconify icon="carbon:view" />
          </Link>
        ),
      },
    ],
    [language]
  );

  const todaysAppointments = useMemo(() => {
    if (data && data.appointments && data.appointments.length > 0) {
      return data.appointments.filter(
        (a: any) => fDate(a.date) === fDate(new Date())
      );
    } else {
      return [];
    }
  }, [data, data ? data.appointments : null]);

  useEffect(() => {
    const mn = localStorage.getItem("mobileNumber");
    const isotpverified = localStorage.getItem("isotpverified");
    if (mn && mn.length > 0 && isotpverified && isotpverified === "yes") {
      setMobileNumber(mn);
    }
  }, []);

  const handleQuery = useCallback((query: string) => {
    if (query.length) {
      setQuery(`${phone}${query}`);
    }
  }, []);

  const getProgressPercentage = (updatedAt: Date) => {
    let diff = diffInHours(new Date(), updatedAt);
    if (diff > 10) {
      diff = 10;
    } else if (diff < 1) {
      diff = 1;
    }
    return diff * 9.5;
  };

  const onLogout = () => {
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("isotpverified");
    navigate("/mobile");
  };
  return (
    <>
      <Helmet>
        <title>{`${t("Dashboard")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <Card className={classes.root}>
            <CardHeader
              title={
                <Grid sx={{ textAlign: "right" }}>
                  {mobileNumber && mobileNumber.length > 0 && (
                    <span style={{ marginRight: "15px", fontSize: "15px" }}>
                      <span style={{ marginRight: "10px" }}>
                        {mobileNumber}
                      </span>
                      <span
                        onClick={onLogout}
                        style={{
                          cursor: "pointer",
                          color: "#0000ee",
                          textDecoration: "underline",
                        }}
                      >
                        Logout / लॉग आउट
                      </span>
                    </span>
                  )}

                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    sx={{ float: "right" }}
                    onClick={() => {
                      navigate(
                        mobileNumber && mobileNumber.length > 0
                          ? "/appointment"
                          : "/mobile"
                      );
                    }}
                  >
                    {t("New Appointment") + " / नव नियुक्ति"}
                  </Button>
                </Grid>
              }
            />
            <CardContent>
              <Grid sx={{ textAlign: "left", mb: 5 }}>
                <Grid sx={{ fontWeight: "bold" }}>
                  {t("Today's Appointments") + " / आज की नियुक्तियाँ:"}
                </Grid>
                {todaysAppointments && todaysAppointments.length > 0 ? (
                  todaysAppointments.map((a: any, i: number) => {
                    return (
                      <Grid sx={{ p: 2 }}>
                        <Grid>
                          <Typography sx={{ display: "inline-block", mr: 2 }}>
                            {getScanName(a.scans)}
                          </Typography>
                          <Typography sx={{ display: "inline-block", mr: 2 }}>
                            -
                          </Typography>
                          <Typography sx={{ display: "inline-block" }}>
                            {getTime(a.time)}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Label
                            color={
                              a.status === 0
                                ? "default"
                                : a.status === 1
                                ? "warning"
                                : a.status === 2
                                ? "primary"
                                : a.status === 3
                                ? "info"
                                : a.status === 4
                                ? "error"
                                : "success"
                            }
                          >
                            {a.status === 0
                              ? "Draft / मसौदा"
                              : a.status === 1
                              ? "Confirmed / की पुष्टि"
                              : a.status === 2
                              ? "Scan In Progress / स्कैन प्रगति पर है"
                              : a.status === 3
                              ? "Report In Progress / रिपोर्ट प्रगति पर है"
                              : a.status === 4
                              ? "Report Ready / रिपोर्ट तैयार"
                              : "Completed / पुरा होना।"}
                          </Label>
                        </Grid>
                        {(a.status === 3 || a.status === 4) && (
                          <Grid sx={{ mt: 2 }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                a.status === 4
                                  ? 100
                                  : getProgressPercentage(new Date(a.updatedAt))
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid sx={{ p: 2 }}>
                    {t("No appointmants today.") + " / आज कोई नियुक्ति नहीं."}
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{
                  marginTop: "15px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "7px",
                }}
              >
                {t("All Appointments") + " / सभी नियुक्तियाँ:"}
              </Grid>
              <Table
                columns={columns}
                data={data && data.appointments ? data.appointments : []}
                pagination={data && data.pagination ? data.pagination : null}
                searchFormFields={[
                  {
                    name: "patientName",
                    type: "text",
                    label: t("Patient Name") + " / रोगी का नाम",
                  },
                  {
                    name: "fromDate",
                    type: "date",
                    label: t("From Date") + " / की तिथि से",
                  },
                  {
                    name: "toDate",
                    type: "date",
                    label: t("To Date") + " / तारीख तक",
                  },
                  {
                    name: "token",
                    type: "number",
                    label: t("Token") + " / टोकन",
                  },
                  {
                    name: "referringDoctorName",
                    type: "text",
                    label: t("Refered By") + " / द्वारा उल्लिखित",
                  },
                ]}
                searchFormInitialValues={{
                  patientName: "",
                  fromDate: "",
                  toDate: "",
                  token: "",
                }}
                handleQuery={handleQuery}
                defaultOrderBy="date"
                defaultOrder="desc"
                isLoading={result.isFetching}
              />
            </CardContent>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
};
