// ----------------------------------------------------------------------

const account = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: "/assets/avatar_default.jpg",
  role: "",
};

export default account;
