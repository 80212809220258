import { Helmet } from "react-helmet-async";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Switch,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
// components
import Iconify from "../../components/iconify";
// mock
import Table from "../../components/table";
import { fDateTime } from "../../utils/formatTime";
import { RootState } from "../store";
import {
  useGetScansQuery,
  useAddNewScanMutation,
  useEditScanMutation,
  useDestroyScanMutation,
  useGetapppointmentHoursQuery,
  useEditApppointmentHoursMutation,
} from "../user/slice";
import { Message } from "../../models/message";
import Modal from "../../components/modal";
import Alert from "../../components/alert";
import { DraftScan } from "../api";
import Label from "../../components/label/Label";
// import Loader from "../../components/loader";

export default () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state: RootState) => state.appUser.language);
  const [query, setQuery] = useState("?page=1&limit=all&sort=name asc");
  const [ahQuery, setAHQuery] = useState(
    "?page=1&limit=all&sort=createdAt asc"
  );
  const [message, setMessage] = useState<Message | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingAH, setIsUpdatingAH] = useState(false);
  const [editScan, setEditScan] = useState<DraftScan | null>(null);
  const [itemsToDelete, setItemsToDelete] = useState<Array<any>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const scanResult = useGetScansQuery(query);
  const [addNewScan] = useAddNewScanMutation();
  const [updateScan] = useEditScanMutation();
  const [destroyScan] = useDestroyScanMutation();
  const ahResult = useGetapppointmentHoursQuery(ahQuery);
  const [updateAH] = useEditApppointmentHoursMutation();

  const columns = useMemo(
    () => [
      {
        id: "name",
        label: t("Name"),
        // avatarUrlId: "avatarUrl",
        callback: (row: any) => (
          <span
            onClick={() => setEditScan(row)}
            style={{ cursor: "pointer", fontWeight: "bold" }}
          >
            {row.name}
          </span>
        ),
      },
      { id: "amount", label: t("Amount") },
      { id: "description", label: t("Description") },
      {
        id: "isActive",
        label: t("Status"),
        callback: (row: any) => (
          <Label color={row.isActive ? "success" : "error"}>
            {t(row.isActive ? "Active" : "In Active")}
          </Label>
        ),
      },
      {
        id: "createdAt",
        label: t("CreatedAt"),
        callback: (row: any) => fDateTime(row.createdAt),
      },
    ],
    [language]
  );

  const actions = useMemo(
    () => [
      {
        label: t("Edit"),
        color: "info.dark",
        icon: "eva:edit-fill",
        onClick: (row: any) => {
          setEditScan(row);
        },
      },
      {
        label: t("Delete"),
        color: "error.main",
        icon: "eva:trash-2-outline",
        onClick: (row: any) => {
          setItemsToDelete([row.id]);
        },
      },
    ],
    [language]
  );

  const ahColumns = useMemo(
    () => [
      {
        id: "weekDay",
        label: t("Week Day"),
        // avatarUrlId: "avatarUrl",
      },
      {
        id: "startTime",
        label: t("Start Time"),
        callback: (row: any) => (
          <input
            type="time"
            defaultValue={row.startTime}
            onBlur={(e) => {
              onUpdateAH({ ...row, startTime: e.target.value });
            }}
          />
        ),
      },
      {
        id: "endTime",
        label: t("End Time"),
        callback: (row: any) => (
          <input
            type="time"
            defaultValue={row.endTime}
            onBlur={(e) => {
              onUpdateAH({ ...row, endTime: e.target.value });
            }}
          />
        ),
      },
      {
        id: "isOpen",
        label: t("Open"),
        callback: (row: any) => (
          <Switch
            defaultChecked={row.isOpen}
            color="secondary"
            onChange={(e) => {
              onUpdateAH({ ...row, isOpen: e.target.checked });
            }}
          />
          // <Label color={row.isOpen ? "success" : "error"}>
          //   {t(row.isOpen ? "Open" : "Closed")}
          // </Label>
        ),
      },
    ],
    [language]
  );

  const scanData: any = scanResult.isSuccess
    ? scanResult.data
      ? scanResult.data
      : {}
    : null;
  const ahData: any = ahResult.isSuccess
    ? ahResult.data
      ? ahResult.data
      : {}
    : null;

  useEffect(() => {
    if (scanResult.data?.error) {
      setMessage({ type: "error", message: scanResult.data.error });
    }
  }, [scanResult.data?.error]);

  const handleQuery = useCallback((query: string) => {
    if (query.length) {
      setQuery(query);
    }
  }, []);

  const onDeleteSelected = useCallback(async () => {
    if (itemsToDelete.length) {
      setIsDeleting(true);
      try {
        const { message, error, invalidData } = await destroyScan(
          itemsToDelete.join()
        ).unwrap();
        setIsDeleting(false);
        setItemsToDelete([]);
        if (message) {
          setMessage({ type: "success", message });
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: invalidData.id });
        }
      } catch (error: any) {
        setIsDeleting(false);
        setItemsToDelete([]);
        setMessage({ type: "error", message: error.message });
      }
    }
  }, [itemsToDelete, destroyScan]);

  const onSubmitScan = async () => {
    if (
      editScan &&
      editScan.name &&
      editScan.name.length > 0 &&
      editScan.amount
    ) {
      setMessage(null);
      setError(null);
      setIsLoading(true);
      if (editScan.id && editScan.id.length > 0) {
        try {
          const { scan, error, invalidData } = await updateScan({
            id: editScan.id,
            name: editScan.name,
            amount: editScan.amount,
            description: editScan.description,
            isActive: editScan.isActive,
          } as unknown as DraftScan).unwrap();
          setIsLoading(false);
          if (scan) {
            const message = {
              type: "success",
              message: t("Scan updated successfully."),
            };
            setEditScan(null);
            setQuery(query + "");
          }
          if (error) {
            setMessage({ type: "error", message: error });
          }
          if (invalidData) {
            setError(invalidData);
            setMessage({
              type: "error",
              message: t("Please correct the errors"),
            });
          }
        } catch (error: any) {
          setMessage({ type: "error", message: error.message });
        }
      } else {
        try {
          const { scan, error, invalidData } = await addNewScan({
            name: editScan.name,
            amount: editScan.amount,
            description: editScan.description,
          } as DraftScan).unwrap();
          setIsLoading(false);
          if (scan) {
            const message = {
              type: "success",
              message: t("Scan created successfully."),
            };
            setEditScan(null);
            setQuery(query + "");
          }
          if (error) {
            setMessage({ type: "error", message: error });
          }
          if (invalidData) {
            setError(invalidData);
            setMessage({
              type: "error",
              message: t("Please correct the errors"),
            });
          }
        } catch (error: any) {
          setMessage({ type: "error", message: error.message });
        }
      }
    } else {
      setMessage({ type: "error", message: "Please fill name and amount." });
    }
  };

  const onUpdateAH = async (appointmentHours: any) => {
    setMessage(null);
    setError(null);
    setIsUpdatingAH(true);
    try {
      const { scan, error, invalidData } = await updateAH(
        appointmentHours
      ).unwrap();
      setIsUpdatingAH(false);
      if (scan) {
        const message = {
          type: "success",
          message: t("Appointment Hours updated successfully."),
        };
        setEditScan(null);
        setQuery(query + "");
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        setError(invalidData);
        let msg = "";
        for (const key in invalidData) {
          msg += (msg.length > 0 ? ", " : "") + invalidData[key];
        }
        setMessage({
          type: "error",
          message: msg,
        });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Settings")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("Settings")}
          </Typography>
        </Stack>

        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("Scans")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setEditScan({
                name: "",
                amount: "",
                description: "",
                isActive: true,
              } as unknown as DraftScan);
            }}
          >
            {t("New Scan")}
          </Button>
        </Stack>
        <Table
          columns={columns}
          actions={actions}
          data={scanData && scanData.scans ? scanData.scans : []}
          pagination={
            scanData && scanData.pagination ? scanData.pagination : null
          }
          checkboxId="id"
          hasCheckbox={true}
          onDeleteSelected={setItemsToDelete}
          clearSelection={!isDeleting}
          searchFormFields={[{ name: "name", type: "text", label: t("Name") }]}
          searchFormInitialValues={{ name: "" }}
          handleQuery={handleQuery}
          defaultOrderBy="name"
          defaultOrder="asc"
          isLoading={scanResult.isFetching}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={5}
        >
          <Typography variant="h4" gutterBottom>
            {t("Online Appointment Hours")}
          </Typography>
        </Stack>
        <Table
          columns={ahColumns}
          // actions={actions}
          data={
            ahData && ahData.appointmentHourses ? ahData.appointmentHourses : []
          }
          pagination={ahData && ahData.pagination ? ahData.pagination : null}
          isLoading={scanResult.isFetching || isUpdatingAH}
        />
      </Container>

      {editScan && (
        <Modal
          title={
            editScan && editScan.id && editScan.id.length > 0
              ? t("Edit Scan")
              : t("Add scan")
          }
          okLabel={
            (editScan && editScan.id && editScan.id.length > 0
              ? t("Update")
              : t("Create")) + ""
          }
          onOk={onSubmitScan}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => {
            setEditScan(null);
            setMessage(null);
            setError(null);
          }}
          isLoading={isLoading}
          // okIcon="eva:trash-2-fill"
          // okSX={{
          //   bgcolor: "error.dark",
          //   "&:hover": {
          //     bgcolor: "error.darker",
          //   },
          // }}
        >
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <FormControl fullWidth sx={{ p: 2 }}>
            <TextField
              name="name"
              label={t("Scan Name")}
              type="text"
              placeholder={"" + t("Scan Name")}
              required={true}
              autoFocus
              value={editScan.name}
              error={error && error.name}
              helperText={error && error.name ? error.name : ""}
              onChange={(e) => {
                setEditScan({ ...editScan, name: e.target.value } as DraftScan);
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ p: 2 }}>
            <TextField
              name="age"
              label={t("Amount")}
              type="number"
              placeholder={"" + t("Amount")}
              required={true}
              onWheel={(e: any) => e.target.blur()}
              value={editScan.amount}
              error={error && error.amount}
              helperText={error && error.amount ? error.amount : ""}
              onChange={(e) => {
                setEditScan({
                  ...editScan,
                  amount: e.target.value,
                } as DraftScan);
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ p: 2 }}>
            <TextField
              name="description"
              label={t("Description")}
              type="text"
              multiline={true}
              placeholder={"" + t("Description")}
              required={true}
              error={error && error.description}
              helperText={error && error.description ? error.description : ""}
              value={editScan.description}
              onChange={(e) => {
                setEditScan({
                  ...editScan,
                  description: e.target.value,
                } as DraftScan);
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ p: 2 }}>
            <Typography>Status: </Typography>
            <Switch
              defaultChecked={editScan.isActive}
              color="secondary"
              onChange={(e) => {
                setEditScan({
                  ...editScan,
                  isActive: e.target.checked,
                } as DraftScan);
              }}
            />
          </FormControl>
        </Modal>
      )}

      {itemsToDelete && itemsToDelete.length > 0 && (
        <Modal
          title={t("Delete Scan")}
          message={t("Suredeletescan")}
          okLabel={t("Delete") + ""}
          onOk={onDeleteSelected}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => setItemsToDelete([])}
          isLoading={isDeleting}
          okIcon="eva:trash-2-fill"
          okSX={{
            bgcolor: "error.dark",
            "&:hover": {
              bgcolor: "error.darker",
            },
          }}
        />
      )}
      {/* {result && result.isFetching && <Loader />} */}
    </>
  );
};
