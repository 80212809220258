import { emptySplitApi } from "../api/apiSlice";
import { AppUserState, DraftLogin, Error, FormErrors } from "../api";

export const loginApi = emptySplitApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // getAppUser: builder.query<AppUserState | Error, string | void>({
    //     invalidatesTags: ["AppUser"]
    // }),
    login: builder.mutation({
      query: (data: any) => ({
        url: "/account/login",
        method: "POST",
        body: data,
        validateStatus: (response: any, result: any) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),

      invalidatesTags: ["AppUser"],
      // providesTags: (result, error, arg) => {
      //     if (result?.token) {
      //         return ["AppUser", result]
      //     } else {
      //         return ["AppUser"]
      //     }
      // }
    }),
    register: builder.mutation({
      query: (data: any) => ({
        url: "/clients",
        method: "POST",
        body: data,
        validateStatus: (response: any, result: any) => {
          if (result?.invalidData || result?.error) {
            return true;
          }
          return response.ok;
        },
      }),

      invalidatesTags: ["Client"],
      // providesTags: (result, error, arg) => {
      //     if (result?.token) {
      //         return ["AppUser", result]
      //     } else {
      //         return ["AppUser"]
      //     }
      // }
    }),
  }),
});

export const {
  // useGetAppUserQuery,
  useLoginMutation,
  useRegisterMutation,
} = loginApi;
