import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import { Login } from "./pages/login";
import Appointment from "./pages/appointment";
// import PaymentSuccess from "./pages/payment-success";
import Dashboard from "./pages/dashboard";
import Appointments from "./pages/appointments";
import Mobile from "./pages/mobile";
import OTP from "./pages/otp";
import PatentDashboard from "./pages/patent-dashboard";
import EditAppointment from "./pages/edit-appointment";
import NewAppointment from "./pages/dashboard/appointment";
import ViewAppointment from "./pages/view-appointment";
import View from "./pages/dashboard/view";
import {
  List as UserList,
  Add as AddUser,
  Edit as EditUser,
  Profile,
} from "./pages/user";
import Settings from "./pages/settings";
import Page404 from "./pages/404";

export interface IRouter {
  appUser: any;
}
export default ({ appUser }: IRouter) => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      // path: "/admin",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },

        { path: "dashboard", element: <Dashboard /> },
        { path: "appointments", element: <Appointments /> },
        { path: "appointment/edit/:id", element: <EditAppointment /> },
        { path: "new-appointment", element: <NewAppointment /> },
        // { path: "profile", element: <Profile /> },
        { path: "user", element: <UserList /> },
        { path: "user/add", element: <AddUser /> },
        { path: "user/edit/:id", element: <EditUser /> },
        { path: "settings", element: <Settings /> },
        { path: "todays-appointments", element: <View /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard" />, index: true },
        { path: "mobile", element: <Mobile /> },
        { path: "otp", element: <OTP /> },
        { path: "appointment", element: <Appointment /> },
        // { path: "payment-success", element: <PaymentSuccess /> },
        { path: "patient/:phone", element: <PatentDashboard /> },
        { path: "appointment/view/:id", element: <ViewAppointment /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
};
