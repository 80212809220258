import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
// sections
// import { LoginForm } from "../../sections/auth/login";
import LoginForm from "./LoginForm";
import { RootState } from "../store";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }: any) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export const Login = () => {
  const { t, i18n } = useTranslation();
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const appUser = useSelector((state: RootState) => state.appUser);

  useEffect(() => {
    if (appUser && appUser.token && appUser.token.length > 0) {
      navigate("/dashboard", { replace: true });
    }
  }, [appUser]);

  return (
    <>
      <Helmet>
        <title>{`${t("Login")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t("HiWelcomeBack")}
            </Typography>
            <img
              src="/assets/illustrations/illustration_login.png"
              alt={t("Login") + ""}
              style={{ maxWidth: "60%", marginLeft: "60px" }}
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom sx={{ mb: 6 }}>
              {t("Signinto")}
            </Typography>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              {t("Donthaveanaccount")} {""}
              <Link
                variant="subtitle2"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/register")}
              >
                {t("Getstarted")}
              </Link>
            </Typography> */}

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify
                  icon="eva:google-fill"
                  color="#DF3E30"
                  width={22}
                  height={22}
                />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify
                  icon="eva:facebook-fill"
                  color="#1877F2"
                  width={22}
                  height={22}
                />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify
                  icon="eva:twitter-fill"
                  color="#1C9CEA"
                  width={22}
                  height={22}
                />
              </Button>
            </Stack> */}

            {/* <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("OR")}
              </Typography>
            </Divider> */}

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};
