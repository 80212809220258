import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { AppUser } from "./pages/api";
import { APP_LANGUAGE, AUTH_APP_USER, DEFAULT_LANGUAGE } from "./constants";
import { setAppUser, setLanguage } from "./pages/login/appUserSlice";
import { useGetUserQuery } from "./pages/user/slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { RootState } from "./pages/store";
import { cloneDeep } from "lodash";

export default function App() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    let lang: string | null = localStorage.getItem(APP_LANGUAGE);
    if (
      (!lang || lang.length < 1) &&
      DEFAULT_LANGUAGE &&
      DEFAULT_LANGUAGE.length > 0
    ) {
      lang = DEFAULT_LANGUAGE;
    }
    if (lang && lang.length > 0) {
      dispatch(setLanguage(lang));
      i18n
        .changeLanguage(lang)
        .then(() => {})
        .catch((err) => console.log(err));
    }

    const storage: string | null = localStorage.getItem(AUTH_APP_USER);
    if (storage && storage.length > 0) {
      let appUser: AppUser | null = null;
      try {
        appUser = JSON.parse(storage);
      } catch (ex) {
        appUser = null;
      }
      if (appUser && appUser.token) {
        // setAppUserLocal(appUser);
        dispatch(setAppUser(appUser));
      }
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router
            appUser={
              localStorage.getItem(AUTH_APP_USER)
                ? JSON.parse(
                    localStorage.getItem(AUTH_APP_USER)?.toString() + ""
                  )
                : null
            }
          />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
