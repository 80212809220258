import { Helmet } from "react-helmet-async";
import { useMemo, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { RootState } from "../store";
import { currentDate, fDate } from "../../utils/formatTime";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify/Iconify";
import { Field, Formik } from "formik";
import { Schema } from "./Schema";
import { useGetScansQuery } from "../user/slice";
import {
  useGetAppointmentQuery,
  useEditAppointmentMutation,
  useUploadReportMutation,
  useDestroyReportMutation,
} from "../appointment/slice";
import { getTime, getTotalAmount } from "../../utils";
import Loader from "../../components/loader/Loader";
import Table from "../../components/table/Table";
import Modal from "../../components/modal";
import { S3_BUCKET_BASE_URL } from "../../constants";

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    margin: "1rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

export default () => {
  const params = useParams();
  const { id } = params;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [message, setMessage] = useState<Message | null>(null);
  const [reportNameError, setReportNameError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [removedFile, setRemovedFile] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isMaxScreenShot, setIsMaxScreenShot] = useState<boolean>(false);
  const [print, setPrint] = useState<any | null>(null);
  const printEl = useRef<HTMLDivElement>(null);

  const result = useGetAppointmentQuery(id + "");
  const initialValues = useMemo(() => {
    if (result.isSuccess && result.data.appointment) {
      let scanId: any = [];
      if (
        result.data.appointment &&
        result.data.appointment.scans &&
        result.data.appointment.scans.length > 0
      ) {
        result.data.appointment.scans.map((s: any) => {
          scanId.push(s.scan);
        });
      }
      return { ...result.data.appointment, scanId };
    } else {
      return {
        patientName: "",
        patientAge: "",
        patientGender: "M",
        referringDoctorName: "",
        scanId: [],
        date: currentDate("yyyy-MM-dd"),
        time: "",
        reports: [],
        isAdvancePaid: false,
        isTotalAmountPaid: false,
        discount: 0,
      };
    }
  }, [result.isSuccess, result.data?.appointment]);

  const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const [update] = useEditAppointmentMutation();
  const [upload] = useUploadReportMutation();
  const [destroyReport] = useDestroyReportMutation();

  const genderOptions = useMemo(
    () => [
      { value: "M", label: t("Male") },
      { value: "F", label: t("Female") },
      { value: "O", label: t("Other") },
    ],
    []
  );

  const scanResult = useGetScansQuery(scanQuery);
  const scanList: any = scanResult.isSuccess
    ? scanResult.data && scanResult.data.scans
      ? scanResult.data.scans
      : []
    : [];

  useEffect(() => {
    if (result && result.data && result.data.appointment) {
      if (selectedFile) {
        setSelectedFile({
          ...selectedFile,
          name:
            result.data.appointment.patientName +
            "_" +
            currentDate("dd-mm-yyyy"),
        });
      } else {
        setSelectedFile({
          name:
            result.data.appointment.patientName +
            "_" +
            currentDate("dd-mm-yyyy"),
          file: null,
        });
      }
    }
  }, [result]);

  const onSubmit = async (values: any, actions: any) => {
    try {
      if (values.patientAge === "") {
        values.patientAge = 0;
      } else {
        values.patientAge = parseInt(values.patientAge);
      }
      values.advanceAmount = 100;
      let totalAmount = 0;
      values.scanId.map((s: any) => {
        totalAmount += s.amount;
      });
      values.totalAmount = totalAmount;
      setMessage(null);
      actions.setErrors(null);
      const { appointment, error, invalidData } = await update({
        ...values,
        scanId: values.scanId.map((s: any) => s.id),
      }).unwrap();
      actions.setSubmitting(false);
      if (appointment) {
        setMessage({
          type: "success",
          message: t("Apponitment updated successfully."),
        });
        navigate("/appointments");
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        actions.setErrors(invalidData);
        setMessage({ type: "error", message: t("Pleasecorrecterrors") });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const onUpload = async (props: any, e: any) => {
    try {
      setReportNameError(null);
      if (
        selectedFile &&
        selectedFile.name &&
        selectedFile.name.length > 0 &&
        selectedFile.file
      ) {
        const nameArr = selectedFile.file.name.split(".");
        const fileName =
          nameArr && nameArr.length > 1
            ? id + "-" + new Date().getTime() + "." + nameArr[1]
            : selectedFile.file.name;
        setMessage(null);
        setIsUploading(true);
        let form = new FormData();
        form.append("file", selectedFile.file, fileName);
        const { appointment, error, invalidData } = await upload({
          id,
          name: selectedFile.name,
          fileName,
          data: form,
        }).unwrap();
        setIsUploading(false);
        if (appointment) {
          props.values.reports = appointment.reports;
          props.handleChange(e);
          setSelectedFile(null);
          setMessage({
            type: "success",
            message: t("Report uploaded successfully."),
          });
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({ type: "error", message: t("Pleasecorrecterrors") });
        }
      } else {
        if (selectedFile && selectedFile.name && selectedFile.name.length > 0) {
          setMessage({
            type: "error",
            message: t("Please select the file to upload."),
          });
        } else {
          setReportNameError("Please enter the Report Name.");
        }
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const onDestroyReport = async (id: string, props: any, e: any) => {
    try {
      setMessage(null);
      setIsUploading(true);
      const { message, error, invalidData } = await destroyReport(id).unwrap();
      setIsUploading(false);
      if (message) {
        if (props.values.reports && props.values.reports.length > 0) {
          props.values.reports = props.values.reports.filter(
            (f: any) => f.id !== id
          );
        }
        props.handleChange(e);
        setMessage({ type: "success", message });
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
      if (invalidData) {
        setMessage({ type: "error", message: invalidData.id });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const onPrint = () => {
    if (printEl.current) {
      const w = window.open();
      w?.document.write(printEl?.current!.innerHTML);
      setTimeout(function () {
        w?.print();
        w?.close();
      }, 500);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Edit Appointment")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container maxWidth="xl">
        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Card className={classes.root}>
          <CardHeader title={t("Edit Appointment")} />
          <CardContent>
            {result.isSuccess ? (
              <Formik
                enableReinitialize={false}
                validateOnChange={false}
                // validateOnBlur={false}
                initialValues={initialValues}
                validationSchema={Schema(t)}
                onSubmit={onSubmit}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <Grid
                      sx={{
                        px: 3,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Token: {props.values.token}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        px: 3,
                        py: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "inline-block",
                          mr: 2,
                        }}
                      >
                        {fDate(props.values.date)}
                      </Typography>

                      <Typography
                        sx={{ fontWeight: "bold", display: "inline-block" }}
                      >
                        {getTime(props.values.time)}
                      </Typography>
                    </Grid>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="phone"
                        render={({ field }: any) => (
                          <TextField
                            name="phone"
                            label={t("Phone Number")}
                            type="text"
                            placeholder={"" + t("Phone Number")}
                            required={true}
                            autoFocus
                            error={props.errors && props.errors.phone}
                            helperText={props.errors && props.errors.phone}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="patientName"
                        render={({ field }: any) => (
                          <TextField
                            name="patientName"
                            label={t("Patient Name")}
                            type="text"
                            placeholder={"" + t("Patient Name")}
                            required={true}
                            error={props.errors && props.errors.patientName}
                            helperText={
                              props.errors && props.errors.patientName
                            }
                            {...field}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="patientAge"
                        render={({ field }: any) => (
                          <TextField
                            name="patientAge"
                            label={t("Patient Age")}
                            type="number"
                            placeholder={"" + t("Patient Age")}
                            required={true}
                            error={props.errors && props.errors.patientAge}
                            helperText={props.errors && props.errors.patientAge}
                            onWheel={(e: any) => e.target.blur()}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="patientGender"
                        render={({ field }: any) => (
                          <TextField
                            select={true}
                            name="patientGender"
                            label={t("Patient Gender")}
                            required={true}
                            error={props.errors && props.errors.patientGender}
                            helperText={
                              props.errors && props.errors.patientGender
                            }
                            {...field}
                            sx={{ textAlign: "left" }}
                          >
                            {genderOptions.map((o: any, i: number) => (
                              <MenuItem key={"ptopt" + i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="referringDoctorName"
                        render={({ field }: any) => (
                          <TextField
                            name="referringDoctorName"
                            label={t("Refered By Doctor")}
                            type="text"
                            placeholder={"" + t("Refered By Doctor")}
                            error={
                              props.errors && props.errors.referringDoctorName
                            }
                            helperText={
                              props.errors && props.errors.referringDoctorName
                            }
                            {...field}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ p: 2 }}>
                      <Field
                        name="scanId"
                        render={({ field }: any) => (
                          <Autocomplete
                            // {...field}
                            multiple
                            limitTags={2}
                            id="scanId"
                            options={scanList}
                            defaultValue={props.values.scanId}
                            getOptionLabel={(option: any) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Scan"
                                placeholder="Select Scan"
                              />
                            )}
                            // sx={{ width: "500px" }}
                            onChange={(e: any, newValue: any) => {
                              // console.log(newValue);
                              props.values.scanId = newValue;
                              props.handleChange(e);
                            }}
                          />
                        )}
                      />
                      {props.errors && props.errors.scanId && (
                        <div
                          style={{
                            lineHeight: "1.5",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            textAlign: "left",
                            marginTop: "3px",
                            marginLeft: "14px",
                            color: "#FF4842",
                          }}
                        >
                          {props.errors.scanId + ""}
                        </div>
                      )}
                    </FormControl>

                    <Grid sx={{ p: 2 }}>
                      <Typography
                        component="div"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {t("Payment")}:
                      </Typography>
                      {props.values.scanId && props.values.scanId.length > 0 ? (
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <Table
                              columns={[
                                {
                                  id: "name",
                                  label: t("Scan"),
                                },
                                {
                                  id: "amount",
                                  label: t("Amount"),
                                },
                              ]}
                              summaryRow={[
                                { value: "Total", alignRight: true },
                                {
                                  value: getTotalAmount(props.values.scanId),
                                },
                              ]}
                              data={props.values.scanId}
                              hasPaging={false}
                              minWidth={100}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid>No data found!</Grid>
                      )}
                    </Grid>

                    <Grid>
                      <FormControl sx={{ p: 2 }}>
                        <Field
                          name="discount"
                          render={({ field }: any) => (
                            <TextField
                              name="discount"
                              label={t("Discount")}
                              type="number"
                              placeholder={"" + t("Discount")}
                              required={false}
                              error={props.errors && props.errors.discount}
                              helperText={props.errors && props.errors.discount}
                              {...field}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid sx={{ pl: 2, fontWeight: "bold" }}>{`${t(
                      "Total Amount"
                    )}: ${
                      getTotalAmount(props.values.scanId) -
                      props.values.discount
                    }`}</Grid>

                    <Grid sx={{ pl: 2, mt: 2, mb: 2 }}>
                      <Grid style={{ fontWeight: "bold" }}>{`${t(
                        "Amount Paid in advance"
                      )}: ${
                        props.values.isAdvancePaid
                          ? props.values.advanceAmount
                          : props.values.isTotalAmountPaid
                          ? props.values.totalAmount
                          : 0
                      }`}</Grid>
                    </Grid>

                    <Grid sx={{ pl: 2, mt: 2, mb: 2 }}>
                      <Grid style={{ fontWeight: "bold" }}>{`Reference Id: ${
                        props.values.paymentId ? props.values.paymentId : ""
                      }`}</Grid>
                    </Grid>

                    {props.values.advanceFile &&
                    props.values.advanceFile.length > 0 ? (
                      <Grid sx={{ pl: 2, mt: 2, mb: 2 }}>
                        <Grid style={{ fontWeight: "bold" }}>
                          {t("Payment Screenshot:")}
                        </Grid>
                        <Grid>
                          <img
                            src={S3_BUCKET_BASE_URL + props.values.advanceFile}
                            style={{
                              width: isMaxScreenShot ? "75vw" : "250px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsMaxScreenShot(!isMaxScreenShot);
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid sx={{ pl: 2, mt: 2, mb: 2 }}>
                        {t("Payment Screenshot not found.")}
                      </Grid>
                    )}

                    <Grid sx={{ pl: 2, mt: 2, mr: 2, display: "inline-block" }}>
                      <Checkbox
                        checked={props.values.isTotalAmountPaid ? true : false}
                        onChange={(e: any) => {
                          props.values.isTotalAmountPaid = e.target.checked;
                          props.handleChange(e);
                          setSelectedTime(selectedTime + " ");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <Typography
                        component="span"
                        sx={{ mr: 3, fontWeight: "bold" }}
                      >
                        {t("Total Amount Paid")}
                      </Typography>

                      <LoadingButton
                        type="button"
                        loading={false}
                        loadingPosition="start"
                        variant="contained"
                        // size="small"
                        disabled={
                          !props.values.isTotalAmountPaid ||
                          !props.values.scanId ||
                          props.values.scanId.length <= 0
                        }
                        onClick={(e) => {
                          setPrint(props.values);
                        }}
                      >
                        {t("Print Bill")}
                      </LoadingButton>
                    </Grid>

                    <Grid
                      sx={{
                        p: 3,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Report:
                      </Typography>
                      {props.values.reports &&
                      props.values.reports.length > 0 ? (
                        <Grid>
                          {props.values.reports.map((f: any, i: number) => (
                            <Grid key={"rpt-" + i}>
                              <a
                                href={S3_BUCKET_BASE_URL + f.fileName}
                                target="_blank"
                              >
                                {f.name}
                              </a>
                              {isUploading ? (
                                <Iconify
                                  icon="eos-icons:three-dots-loading"
                                  sx={{
                                    mb: -0.4,
                                    ml: 1,
                                    color: "error.main",
                                    height: 20,
                                  }}
                                />
                              ) : (
                                <span title={t("Remove this file") + ""}>
                                  <Iconify
                                    icon="ph:trash-fill"
                                    sx={{
                                      mb: -0.4,
                                      ml: 1,
                                      cursor: "pointer",
                                      color: "error.main",
                                      width: 20,
                                      height: 20,
                                    }}
                                    onClick={(e: any) => {
                                      // props.values.report = props.values.report
                                      //   .split(",")
                                      //   .filter((g: string) => g !== f)
                                      //   .join(",");
                                      onDestroyReport(f.id, props, e);
                                      // props.handleChange(e);
                                      setRemovedFile(f);
                                    }}
                                  />
                                </span>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid>No reports found!!!</Grid>
                      )}
                    </Grid>
                    <Grid
                      sx={{
                        p: 3,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                        Upload Report:
                      </Typography>
                      <TextField
                        name="newFileName"
                        label={""}
                        type="text"
                        placeholder={"" + t("Report Name")}
                        error={
                          reportNameError && reportNameError.length > 0
                            ? true
                            : false
                        }
                        helperText={reportNameError}
                        value={
                          selectedFile && selectedFile.name
                            ? selectedFile.name
                            : ""
                        }
                        onChange={(e: any) => {
                          setSelectedFile({
                            ...selectedFile,
                            name: e.target.value,
                          });
                        }}
                        style={{ marginRight: "15px" }}
                      />
                      {selectedFile && selectedFile.file ? (
                        <input
                          type="file"
                          name="report"
                          onChange={(e: any) => {
                            setSelectedFile({
                              name:
                                selectedFile && selectedFile.name
                                  ? selectedFile.name
                                  : "",
                              file: e.target.files[0],
                            });
                          }}
                          style={{ lineHeight: "53px", marginRight: "15px" }}
                        />
                      ) : (
                        <input
                          type="file"
                          name="report"
                          value=""
                          onChange={(e: any) => {
                            setSelectedFile({
                              name:
                                selectedFile && selectedFile.name
                                  ? selectedFile.name
                                  : "",
                              file: e.target.files[0],
                            });
                          }}
                          style={{ lineHeight: "53px", marginRight: "15px" }}
                        />
                      )}

                      <LoadingButton
                        type="button"
                        loading={isUploading}
                        loadingPosition="start"
                        startIcon={<Iconify icon="basil:upload-solid" />}
                        variant="contained"
                        // size="small"
                        disabled={!selectedFile || !selectedFile.file}
                        onClick={(e) => onUpload(props, e)}
                      >
                        {t("Upload")}
                      </LoadingButton>
                    </Grid>

                    <Grid
                      sx={{
                        p: 3,
                        textAlign: "center",
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        loading={props.isSubmitting}
                        loadingPosition="start"
                        startIcon={<Iconify icon="eva:save-fill" />}
                        variant="contained"
                        fullWidth
                        size="large"
                      >
                        {t("Submit")}
                      </LoadingButton>
                    </Grid>
                  </form>
                )}
              </Formik>
            ) : (
              <Loader />
            )}
          </CardContent>
        </Card>
      </Container>

      {print && print.scanId && (
        <Modal
          title={t("Bill")}
          okLabel={t("Print") + ""}
          onOk={onPrint}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => setPrint(null)}
        >
          <div ref={printEl}>
            <div
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
                fontSize: "16px",
                color: "#0a0482",
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
              }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  src="/assets/logo.png"
                  className="img-print"
                  style={{ height: "45px", display: "inline" }}
                />
              </div>
              <div
                style={{
                  marginTop: "3px",
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: 600,
                }}
              >
                Rani Basant Diagnostic
              </div>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                236-A Lukerganj, Prayagraj-211001.
              </div>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                9452595128
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span>
                  {print?.token && (
                    <span style={{ fontWeight: "bold" }}>
                      {`${t("Token")} # ${print?.token}`}
                    </span>
                  )}
                </span>
                <span>
                  {/* <span>{t("Date")}:</span> */}
                  <span
                    style={{
                      borderBottom: "1px dotted #0a0482",
                      display: "inline-block",
                      height: "20px",
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    {`${fDate(print?.date, "dd/MM/yyyy")} ${getTime(
                      print?.time
                    )}`}
                  </span>
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #0a0482",
                  marginTop: "5px",
                }}
              >
                {print?.patientName}
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #0a0482",
                  marginTop: "5px",
                }}
              >
                <span>{print?.phone}</span>
              </div>
              {/* {print?.customer &&
                print?.customer?.address &&
                print?.customer.address?.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #0a0482",
                      marginTop: "3px",
                    }}
                  >
                    {print?.customer.address}
                  </div>
                )} */}
              <div style={{ marginTop: "7px", fontSize: "15px" }}>
                {`Refered By - ${print?.referringDoctorName}`}
              </div>
              <div>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #0a0482",
                    captionSide: "bottom",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead style={{ borderBottom: "1px solid #0a0482" }}>
                    <tr>
                      <th
                        style={{
                          fontWeight: 500,
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {t("Print.Description")}
                      </th>
                      <th
                        style={{
                          fontWeight: 500,
                          verticalAlign: "center",
                          color: "#0a0482",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {t("Print.Amount")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {print?.scanId &&
                      print?.scanId.length > 0 &&
                      print?.scanId.map((p: any, i: number) => {
                        return (
                          <tr style={{ height: "30px" }}>
                            <td
                              style={{
                                verticalAlign: "center",
                                borderRight: "1px solid #0a0482",
                                color: "#0a0482",
                                padding: "5px",
                              }}
                            >
                              {p.name}
                            </td>
                            <td
                              style={{
                                verticalAlign: "center",
                                color: "#0a0482",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              {p.amount}
                            </td>
                          </tr>
                        );
                      })}
                    <tr style={{ height: "50px" }}>
                      <td
                        style={{
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          padding: "5px",
                          borderTop: "1px solid #0a0482",
                          textAlign: "right",
                        }}
                      >
                        <div>{t("Discount")}</div>
                        <div>{t("Print.TotalAmount")}</div>
                      </td>
                      <td
                        style={{
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          padding: "5px",
                          borderTop: "1px solid #0a0482",
                        }}
                      >
                        <div>
                          {print.discount &&
                          print.discount != 0 &&
                          print.discount != "0"
                            ? print.discount
                            : 0}
                        </div>
                        <div>
                          {getTotalAmount(print.scanId) - print.discount}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div style={{textAlign: 'right'}}><span>For</span> <span style={{fontWeight: 500}}>{t('Print.Footer.ClientName')}</span></div> */}
              {/* <div
                style={{
                  textAlign: "right",
                  fontSize: "15px",
                  fontWeight: 600,
                  marginTop: "10px",
                }}
              >{t('')}</div> */}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
