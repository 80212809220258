import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
// import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import NotificationsPopover from "./NotificationsPopover";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const CLOSE_NAV_WIDTH = 64;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledToolbar = styled(Toolbar)(({ theme }: any) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const AppoinmentLinks = styled("span")(({ theme }) => ({
  marginRight: "15px",
  display: "inline",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const useStyles = makeStyles((theme) => ({
  appoinmentLink: {
    textDecoration: "none",
    fontWeight: "600",
    color: "black",
    "&:hover": {
      color: "#FF000",
    },
  },
}));
// ----------------------------------------------------------------------

Header.propTypes = {
  openNav: PropTypes.bool,
  onOpenNav: PropTypes.func,
};

export default function Header({ openNav, onOpenNav }: any) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const StyledRoot = styled(AppBar)(
    ({ theme }: any) =>
      ({
        ...bgBlur({ color: theme.palette.background.default }),
        boxShadow: "none",
        [theme.breakpoints.up("lg")]: {
          width: `calc(100% - ${
            openNav ? NAV_WIDTH + 1 : CLOSE_NAV_WIDTH + 1
          }px)`,
        },
      } as any)
  );

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Typography variant="h4" sx={{ color: "#000000" }}>
          {t("HiWelcomeBack")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AppoinmentLinks>
            <Link
              to="/appointments"
              className={classes.appoinmentLink}
              style={{
                marginRight: "15px",
              }}
            >
              {t("View All Appointments")}
            </Link>
            <Link
              to="/new-appointment"
              className={classes.appoinmentLink}
              style={{
                marginRight: "10px",
              }}
            >
              {t("Add Appointment")}
            </Link>
          </AppoinmentLinks>

          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
