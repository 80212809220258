export const getTime = (time: string) => {
  if (time && time.length > 0) {
    let splitArray = time.split(":");
    let hours = parseInt(splitArray[0]);
    let minutes = parseInt(splitArray[1]);
    return `${hours > 9 ? (hours > 12 ? hours - 12 : hours) : "0" + hours}:${
      minutes > 9 ? minutes : "0" + minutes
    } ${hours > 12 ? "PM" : "AM"}`;
  } else {
    return "";
  }
};

export const getScanName = (scans: Array<any>) => {
  if (scans && scans.length > 0) {
    return scans.map((s: any) => s.scan.name).join(", ");
  } else {
    return "";
  }
};

export const getTotalAmount = (scans: Array<any>) => {
  let total = 0;
  if (scans && scans.length > 0) {
    scans.map((s: any) => {
     total += s.amount;
    });
  }
  return total;
};