import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Checkbox,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Alert from "../../components/alert";
import { Message } from "../../models/message";
import { makeStyles } from "@mui/styles";
import { useCreatePatientMutation } from "../appointment/slice";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    width: "100%",
    margin: "3rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
});

export default () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [message, setMessage] = useState<Message | null>(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createPatient] = useCreatePatientMutation();
  const [isOpted, setIsOpted] = useState(false);

  useEffect(() => {
    const mn = localStorage.getItem("mobileNumber");
    const isotpverified = localStorage.getItem("isotpverified");
    if (mn && mn.length > 0 && isotpverified && isotpverified === "yes") {
      navigate("/appointment");
    }
  }, []);

  const handleChange = (event: any) => {
    setMobileNumber(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (mobileNumber && mobileNumber.length === 10) {
      try {
        setMessage(null);
        setIsLoading(true);
        const { patient, error, invalidData } = await createPatient({
          phone: mobileNumber,
          isOpted,
        }).unwrap();
        setIsLoading(false);
        if (patient && patient.phone) {
          setMessage({
            type: "success",
            message: t("OTP sent to your mobile."),
          });
          localStorage.setItem("mobileNumber", mobileNumber);
          navigate("/otp");
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          setMessage({
            type: "error",
            message: t("Failed to send OTP. Please try again."),
          });
        }
      } catch (error: any) {
        setMessage({ type: "error", message: error.message });
      }
    } else {
      setMessage({
        type: "error",
        message: t("Please fill the valid mobile number."),
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("MobileNumber")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          {message && message?.message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}

          <Card className={classes.root}>
            <CardHeader
              title={
                <Typography variant="h5" component="h2">
                  {t("EnterMobileNumber") + " / मोबाइल नंबर दर्ज करें"}
                </Typography>
              }
            />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  label={t("MobileNumber") + " / मोबाइल नंबर"}
                  variant="outlined"
                  fullWidth
                  value={mobileNumber}
                  onChange={handleChange}
                  type="tel"
                  required
                  inputProps={{ pattern: "[0-9]{10}", maxLength: 10 }}
                  helperText={
                    t("MobileNumberHelperText") +
                    " / कृपया 10 अंकों का मोबाइल नंबर दर्ज करें।"
                  }
                />
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  sx={{ mt: 5 }}
                >
                  {t("GetOTP")}
                </Button> */}

                <Grid
                  sx={{
                    mt: 2,
                    textAlign: "left",
                  }}
                >
                  <Checkbox
                    checked={isOpted}
                    onChange={(e) => setIsOpted(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                    id="optForMessage"
                  />
                  <label htmlFor="optForMessage">
                    <Typography
                      component="span"
                      sx={{ mr: 3, mb: 3, fontSize: 15, position: "absolute" }}
                    >
                      {t(
                        "I agree to receive messages through SMS and WhatsApp regarding the appointment."
                      ) +
                        " / मैं नियुक्ति के संबंध में एसएमएस और व्हाट्सएप के माध्यमसे संदेश प्राप्त करने के लिए सहमत हूं।."}
                    </Typography>
                  </label>
                </Grid>

                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  loadingPosition="start"
                  // startIcon={<Iconify icon="eva:save-fill" />}
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{ mt: 12 }}
                >
                  {t("GetOTP") + " / ओटीपी प्राप्त करें"}
                </LoadingButton>
              </form>
            </CardContent>
          </Card>
        </StyledContent>
      </Container>
    </>
  );
};
