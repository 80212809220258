import { Helmet } from "react-helmet-async";
import { useMemo, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Autocomplete,
  CircularProgress,
  Checkbox,
  Popper,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Alert from "../../../components/alert";
import { Message } from "../../../models/message";
import Label from "../../../components/label/Label";
import { RootState } from "../../store";
import { currentDate, fDate, isFutureDate } from "../../../utils/formatTime";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify/Iconify";
import { Field, Formik } from "formik";
import { Schema } from "./Schema";
import Table from "../../../components/table/Table";
import { useGetScansQuery } from "../../user/slice";
import {
  useNewAppointmentMutation,
  useLazyGetTimeQuery,
  useLazyGetPatientsByPhoneQuery,
} from "../../appointment/slice";
import { DraftAppointment } from "../../api";
import { getTotalAmount, getTime as getTimeString } from "../../../utils";
import Modal from "../../../components/modal";

const useStyles = makeStyles({
  root: {
    // maxWidth: 300,
    margin: "1rem auto",
    padding: "1rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow here
  },
  patientRow: {
    width: "100%",
    padding: "8px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
});

export default () => {
  const params = useParams();
  const { id } = params;
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const language = useSelector((state: RootState) => state.appUser.language);
  const [message, setMessage] = useState<Message | null>(null);
  const [timeList, setTimeList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");
  const [print, setPrint] = useState<any | null>(null);
  const printEl = useRef<HTMLDivElement>(null);

  const initialValues: DraftAppointment = {
    phone: "",
    patientName: "",
    patientAge: "",
    patientGender: "M",
    referringDoctorName: "",
    scanId: [],
    date: currentDate("yyyy-MM-dd"),
    time: "",
    isSubmitted: false,
    isPaid: false,
    isTotalAmountPaid: false,
    discount: 0,
  } as unknown as DraftAppointment;

  const [scanQuery, setScanQuery] = useState("?page=1&limit=all&sort=name asc");
  const scanResult = useGetScansQuery(scanQuery);
  const scanList: any = scanResult.isSuccess
    ? scanResult.data && scanResult.data.scans
      ? scanResult.data.scans.filter((s) => s.isActive)
      : []
    : [];
  const [addNew] = useNewAppointmentMutation();
  const [getTime, { error }] = useLazyGetTimeQuery();

  const [getPatient] = useLazyGetPatientsByPhoneQuery();

  const genderOptions = useMemo(
    () => [
      { value: "M", label: t("Male") },
      { value: "F", label: t("Female") },
      { value: "O", label: t("Other") },
    ],
    []
  );

  useEffect(() => {
    getAvailableTimes(currentDate("yyyy-MM-dd"));

    const today = new Date().toJSON().slice(0, 10);
    const date = document.getElementById("date-picker");
    date?.setAttribute("min", today);
  }, []);

  const getAvailableTimes = async (date: string) => {
    if (date && date.length > 0 && isFutureDate(new Date(date))) {
      setIsLoading(true);
      const { availableTimes, error, invalidData } = await getTime(
        date
      ).unwrap();
      setIsLoading(false);
      if (availableTimes && availableTimes.length > 0) {
        setTimeList(availableTimes);
      }
      if (error) {
        setMessage({ type: "error", message: error });
      }
    } else {
      setTimeList([]);
    }
  };

  const searchPatients = async (phone: string) => {
    setPatientList([]);
    // setIsLoading(true);
    const { patients, error, invalidData } = await getPatient(
      `${phone}?page=1&limit=10&sort=date desc`
    ).unwrap();
    // setIsLoading(false);
    if (patients && patients.length > 0) {
      setPatientList(patients);
    }
    // if (error) {
    //   setMessage({ type: "error", message: error });
    // }
  };

  const onSubmit = async (values: any, actions: any) => {
    try {
      if (values.scanId && values.scanId.length > 0) {
        const scans = [...values.scanId];
        if (values.patientAge === "") {
          values.patientAge = 0;
        } else {
          values.patientAge = parseInt(values.patientAge);
        }
        values.advanceAmount = 100;
        let totalAmount = 0;
        values.scanId.map((s: any) => {
          totalAmount += s.amount;
        });
        values.totalAmount = totalAmount;
        // values.scanId = values.scanId.map((s: any) => s.id);
        setMessage(null);
        actions.setErrors(null);
        const { appointment, error, invalidData } = await addNew({
          ...values,
          scanId: values.scanId.map((s: any) => s.id),
          isAdvancePaid: false,
          // isTotalAmountPaid: false,
          isByDoctor: true,
        }).unwrap();
        actions.setSubmitting(false);
        if (appointment) {
          // const message = {
          //   type: "success",
          //   message: t("Apponitmentcreatedsuccessfully"),
          // };
          navigate("/dashboard");
        }
        if (error) {
          setMessage({ type: "error", message: error });
        }
        if (invalidData) {
          actions.setErrors(invalidData);
          setMessage({ type: "error", message: t("Pleasecorrecterrors") });
        }
      } else {
        setMessage({ type: "error", message: "Please select the Scan." });
      }
    } catch (error: any) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const onPrint = () => {
    if (printEl.current) {
      const w = window.open();
      w?.document.write(printEl?.current!.innerHTML);
      setTimeout(function () {
        w?.print();
        w?.close();
      }, 500);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("Add Appointment")} | ${t("AppTitle")}`}</title>
      </Helmet>

      <Container maxWidth="xl">
        {message && message?.message && (
          <Alert message={message} onClose={() => setMessage(null)} />
        )}

        <Card className={classes.root}>
          <CardHeader title={t("Add Appointment")} />
          <CardContent>
            <Formik
              enableReinitialize={false}
              validateOnChange={false}
              // validateOnBlur={false}
              initialValues={initialValues}
              validationSchema={Schema(t)}
              onSubmit={onSubmit}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="phone"
                      render={({ field }: any) => (
                        <TextField
                          name="phone"
                          label={t("Phone Number")}
                          type="text"
                          placeholder={"" + t("Phone Number")}
                          required={true}
                          autoFocus
                          error={
                            isSubmitted && props.errors && props.errors.phone
                          }
                          helperText={
                            isSubmitted && props.errors && props.errors.phone
                          }
                          {...field}
                          onChange={(e) => {
                            props.values.phone = e.target.value;
                            props.handleChange(e);
                            if (e.target.value && e.target.value.length >= 10) {
                              searchPatients(e.target.value);
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="patientName"
                      render={({ field }: any) => (
                        <TextField
                          autoComplete="off"
                          name="patientName"
                          label={t("Patient Name")}
                          type="text"
                          placeholder={"" + t("Patient Name")}
                          required={true}
                          autoFocus
                          error={
                            isSubmitted &&
                            props.errors &&
                            props.errors.patientName
                          }
                          helperText={
                            isSubmitted && props.errors
                              ? props.errors.patientName
                              : ""
                          }
                          {...field}
                          onFocus={(e: any) => {
                            setSearchAnchorEl(e.currentTarget);
                          }}
                          onBlur={(e: any) => {
                            setTimeout(() => {
                              setSearchAnchorEl(null);
                            }, 400);
                          }}
                        />
                      )}
                    />
                    <Popper
                      id={id}
                      open={
                        patientList &&
                        patientList.length > 0 &&
                        Boolean(searchAnchorEl)
                      }
                      anchorEl={searchAnchorEl}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderRadius: 1,
                          // px: 2,
                          py: 1,
                          bgcolor: "background.paper",
                          width: searchAnchorEl
                            ? searchAnchorEl.clientWidth
                            : "100%",
                        }}
                      >
                        {patientList.map((p: any) => {
                          return (
                            <Grid
                              onClick={(e: any) => {
                                props.values.patientName = p.patientName;
                                props.values.patientAge = p.patientAge;
                                props.values.patientGender = p.patientGender;
                                props.handleChange(e);
                                setSearchAnchorEl(null);
                              }}
                              className={classes.patientRow}
                            >
                              <Typography
                                component="span"
                                sx={{ fontWeight: "bold" }}
                              >
                                {p.patientName}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ fontWeight: "bold", mx: 2 }}
                              >
                                -
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ fontWeight: "bold" }}
                              >
                                {p.patientAge}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ fontWeight: "bold", mx: 2 }}
                              >
                                -
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ fontWeight: "bold" }}
                              >
                                {p.patientGender === "M"
                                  ? "Male"
                                  : p.patientGender === "F"
                                  ? "Female"
                                  : "Other"}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Box>
                    </Popper>
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="patientAge"
                      render={({ field }: any) => (
                        <TextField
                          name="patientAge"
                          label={t("Patient Age")}
                          type="number"
                          placeholder={"" + t("Patient Age")}
                          required={true}
                          error={
                            isSubmitted &&
                            props.errors &&
                            props.errors.patientAge
                          }
                          helperText={
                            isSubmitted &&
                            props.errors &&
                            props.errors.patientAge
                          }
                          onWheel={(e: any) => e.target.blur()}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="patientGender"
                      render={({ field }: any) => (
                        <TextField
                          select={true}
                          name="patientGender"
                          label={t("Patient Gender")}
                          required={true}
                          error={
                            isSubmitted &&
                            props.errors &&
                            props.errors.patientGender
                          }
                          helperText={
                            isSubmitted && props.errors
                              ? props.errors.patientGender
                              : ""
                          }
                          {...field}
                          sx={{ textAlign: "left" }}
                        >
                          {genderOptions.map((o: any, i: number) => (
                            <MenuItem key={"ptopt" + i} value={o.value}>
                              {o.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="referringDoctorName"
                      render={({ field }: any) => (
                        <TextField
                          name="referringDoctorName"
                          label={t("Refered By Doctor")}
                          type="text"
                          placeholder={"" + t("Refered By Doctor")}
                          error={
                            isSubmitted &&
                            props.errors &&
                            props.errors.referringDoctorName
                          }
                          helperText={
                            isSubmitted && props.errors
                              ? props.errors.referringDoctorName
                              : ""
                          }
                          {...field}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="scanId"
                      render={({ field }: any) => (
                        <Autocomplete
                          // {...field}
                          multiple
                          limitTags={2}
                          id="scanId"
                          options={scanList}
                          getOptionLabel={(option: any) => option.name}
                          defaultValue={props.values.scanId}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Scan"
                              placeholder="Select Scan"
                            />
                          )}
                          // sx={{ width: "500px" }}
                          onChange={(e: any, newValue: any) => {
                            // console.log(newValue);
                            props.values.scanId = newValue;
                            props.handleChange(e);
                          }}
                        />
                      )}
                    />
                    {isSubmitted && props.errors && props.errors.scanId && (
                      <div
                        style={{
                          lineHeight: "1.5",
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          textAlign: "left",
                          marginTop: "3px",
                          marginLeft: "14px",
                          color: "#FF4842",
                        }}
                      >
                        {props.errors.scanId}
                      </div>
                    )}
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 2 }}>
                    <Field
                      name="date"
                      render={({ field }: any) => (
                        <TextField
                          name="date"
                          id="date-picker"
                          label={t("Date")}
                          type="date"
                          placeholder={"" + t("Date")}
                          required={true}
                          // max={new Date().toISOString().split("T")[0]}
                          error={
                            isSubmitted && props.errors && props.errors.date
                          }
                          helperText={
                            isSubmitted && props.errors ? props.errors.date : ""
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...field}
                          onChange={(e: any) => {
                            getAvailableTimes(e.target.value);
                            props.handleChange(e);
                          }}
                        />
                      )}
                    />
                  </FormControl>

                  <Grid
                    sx={{
                      p: 3,
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                      Time:
                    </Typography>
                    {!isLoading && timeList && timeList.length > 0 ? (
                      <Grid sx={{ textAlign: "left" }}>
                        {timeList.map((time: any, i: number) => (
                          <Label
                            key={"time" + i}
                            color={
                              props.values.time === time.value
                                ? "error"
                                : "default"
                            }
                            onClick={(e: any) => {
                              props.values.time = time.value;
                              props.handleChange(e);
                              setSelectedTime(time.value);
                            }}
                            sx={{ mx: 2, my: 1, cursor: "pointer" }}
                          >
                            {time.label}
                          </Label>
                        ))}
                      </Grid>
                    ) : isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Grid sx={{ textAlign: "left" }}>
                        {t("No time slot found for the selected date.")}
                      </Grid>
                    )}
                  </Grid>
                  {isSubmitted && props.errors && props.errors.time && (
                    <Grid
                      sx={{
                        color: "#FF4842",
                        lineHeight: "1.5",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        textAlign: "left",
                        marginRight: "14px",
                        marginLeft: "14px",
                      }}
                    >
                      {props.errors.time}
                    </Grid>
                  )}

                  <Grid sx={{ p: 2 }}>
                    <Typography
                      component="div"
                      sx={{ fontWeight: "bold", mr: 2 }}
                    >
                      {t("Payment")}:
                    </Typography>
                    {props.values.scanId && props.values.scanId.length > 0 ? (
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                          <Table
                            columns={[
                              {
                                id: "name",
                                label: t("Scan"),
                              },
                              {
                                id: "amount",
                                label: t("Amount"),
                              },
                            ]}
                            summaryRow={[
                              { value: "Total" },
                              {
                                value: getTotalAmount(props.values.scanId),
                              },
                            ]}
                            data={props.values.scanId}
                            hasPaging={false}
                            minWidth={100}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid>No data found!</Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl sx={{ p: 2 }}>
                      <Field
                        name="discount"
                        render={({ field }: any) => (
                          <TextField
                            name="discount"
                            label={t("Discount")}
                            type="number"
                            placeholder={"" + t("Discount")}
                            required={false}
                            error={props.errors && props.errors.discount}
                            helperText={props.errors && props.errors.discount}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid sx={{ pl: 2, fontWeight: "bold" }}>{`${t(
                    "Total Amount"
                  )}: ${
                    getTotalAmount(
                      props.values.scanId ? props.values.scanId : []
                    ) - props.values.discount
                  }`}</Grid>

                  <Grid sx={{ mt: 2, mr: 2, display: "inline-block" }}>
                    <Checkbox
                      checked={props.values.isTotalAmountPaid ? true : false}
                      onChange={(e: any) => {
                        props.values.isTotalAmountPaid = e.target.checked;
                        props.handleChange(e);
                        setSelectedTime(selectedTime + " ");
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography component="span" sx={{ mr: 3 }}>
                      {t("Amount Paid")}
                    </Typography>

                    <LoadingButton
                      type="button"
                      loading={false}
                      loadingPosition="start"
                      variant="contained"
                      // size="small"
                      disabled={
                        !props.values.isTotalAmountPaid ||
                        !props.values.scanId ||
                        props.values.scanId.length <= 0
                      }
                      onClick={(e) => {
                        setPrint(props.values);
                      }}
                    >
                      {t("Print Bill")}
                    </LoadingButton>
                  </Grid>

                  <Grid
                    sx={{
                      p: 3,
                      textAlign: "center",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      loading={props.isSubmitting || isLoading}
                      loadingPosition="start"
                      startIcon={<Iconify icon="eva:save-fill" />}
                      variant="contained"
                      fullWidth
                      size="large"
                      onClick={() => {
                        setIsSubmitted(true);
                      }}
                    >
                      {t("Submit")}
                    </LoadingButton>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Container>

      {print && print.scanId && (
        <Modal
          title={t("Bill")}
          okLabel={t("Print") + ""}
          onOk={onPrint}
          cancelLabel={t("Cancel") + ""}
          onCancel={() => setPrint(null)}
        >
          <div ref={printEl}>
            <div
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
                fontSize: "16px",
                color: "#0a0482",
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
              }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  src="/assets/logo.png"
                  className="img-print"
                  style={{ height: "45px", display: "inline" }}
                />
              </div>
              <div
                style={{
                  marginTop: "3px",
                  textAlign: "center",
                  fontSize: "25px",
                  fontWeight: 600,
                }}
              >
                Rani Basant Diagnostic
              </div>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                236-A Lukerganj, Prayagraj-211001.
              </div>
              <div style={{ textAlign: "center", fontSize: "15px" }}>
                9452595128
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span>
                  {print?.token && (
                    <span style={{ fontWeight: "bold" }}>
                      {`${t("Token")} # ${print?.token}`}
                    </span>
                  )}
                </span>
                <span>
                  {/* <span>{t("Date")}:</span> */}
                  <span
                    style={{
                      borderBottom: "1px dotted #0a0482",
                      display: "inline-block",
                      height: "20px",
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    {`${fDate(print?.date, "dd/MM/yyyy")} ${getTimeString(
                      print?.time
                    )}`}
                  </span>
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #0a0482",
                  marginTop: "5px",
                }}
              >
                {print?.patientName}
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #0a0482",
                  marginTop: "5px",
                }}
              >
                <span>{print?.phone}</span>
              </div>
              {/* {print?.customer &&
                print?.customer?.address &&
                print?.customer.address?.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #0a0482",
                      marginTop: "3px",
                    }}
                  >
                    {print?.customer.address}
                  </div>
                )} */}
              <div style={{ marginTop: "7px", fontSize: "15px" }}>
                {`Refered By - ${print?.referringDoctorName}`}
              </div>
              <div>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #0a0482",
                    captionSide: "bottom",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead style={{ borderBottom: "1px solid #0a0482" }}>
                    <tr>
                      <th
                        style={{
                          fontWeight: 500,
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {t("Print.Description")}
                      </th>
                      <th
                        style={{
                          fontWeight: 500,
                          verticalAlign: "center",
                          color: "#0a0482",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        {t("Print.Amount")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {print?.scanId &&
                      print?.scanId.length > 0 &&
                      print?.scanId.map((p: any, i: number) => {
                        return (
                          <tr style={{ height: "30px" }}>
                            <td
                              style={{
                                verticalAlign: "center",
                                borderRight: "1px solid #0a0482",
                                color: "#0a0482",
                                padding: "5px",
                              }}
                            >
                              {p.name}
                            </td>
                            <td
                              style={{
                                verticalAlign: "center",
                                color: "#0a0482",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                              }}
                            >
                              {p.amount}
                            </td>
                          </tr>
                        );
                      })}
                    <tr style={{ height: "50px" }}>
                      <td
                        style={{
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          padding: "5px",
                          borderTop: "1px solid #0a0482",
                          textAlign: "right",
                        }}
                      >
                        <div>{t("Discount")}</div>
                        <div>{t("Print.TotalAmount")}</div>
                      </td>
                      <td
                        style={{
                          verticalAlign: "center",
                          borderRight: "1px solid #0a0482",
                          color: "#0a0482",
                          padding: "5px",
                          borderTop: "1px solid #0a0482",
                        }}
                      >
                        <div>
                          {print.discount &&
                          print.discount != 0 &&
                          print.discount != "0"
                            ? print.discount
                            : 0}
                        </div>
                        <div>
                          {getTotalAmount(print.scanId) - print.discount}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div style={{textAlign: 'right'}}><span>For</span> <span style={{fontWeight: 500}}>{t('Print.Footer.ClientName')}</span></div> */}
              {/* <div
                style={{
                  textAlign: "right",
                  fontSize: "15px",
                  fontWeight: 600,
                  marginTop: "10px",
                }}
              >{t('')}</div> */}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
